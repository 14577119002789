import styled from '@emotion/styled';
import React, { PureComponent } from 'react';
import { ChatMessageGroup } from '../../hooks/useChat';
import { ProfileImage } from '../shared/ProfileImage';
import theme from '../shared/theme';
import ReactMarkdown from 'react-markdown';

type Props = ChatMessageGroup;
export class ChatMessage extends PureComponent<Props> {
  render() {
    const { author, timestamp, messages } = this.props;
    return (
      <Container>
        <ImageContainer>
          <ProfileImage user={author} />
        </ImageContainer>
        <Name>
          {author.displayName} <Timestamp>{timeAgo(timestamp)}</Timestamp>
        </Name>
        <MessageContainer>
          {messages.map((message, index) => (
            <ReactMarkdown key={index} skipHtml linkTarget="_blank" source={message} />
          ))}
        </MessageContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 45px auto;
  grid-template-rows: auto;
  grid-template-areas:
    'image name'
    'image messages';
  margin-bottom: 5px;
`;

const ImageContainer = styled.div`
  margin: 5px;
  grid-area: image;
`;

const Name = styled.span`
  grid-area: name;
  font-weight: bold;
  margin: 5px 10px 0 0;
  font-size: 12px;
`;

const MessageContainer = styled.div`
  flex: 1;
  white-space: pre-line;
  grid-area: messages;
  font-size: 13px;
  overflow-x: hidden;

  & > * {
    margin: 0 3px 3px 0;
  }

  & > pre {
    padding: 5px 10px;
    background: #f5f5f5;
    border: 1px solid #eee;
  }

  & > blockquote {
    border: 1px solid #eee;
    border-left: 5px solid #eee;
    padding: 5px 10px;

    p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
  }

  & > hr {
    border-top: 1px solid #eee;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 100;
  }
`;

const Timestamp = styled.span`
  display: inline-block;
  color: ${theme.colors.lightGrey};
  grid-area: timestamp;
  font-size: 11px;
  font-weight: normal;
`;

function timeAgo(date: Date) {
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + ' years';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' months';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' days';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hours';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes';
  }
  return 'just now';
}
