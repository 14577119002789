import styled from '@emotion/styled';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useState } from 'react';
import { Project, ProjectCategory, ResultsDto, VoteDto } from '../../Api';
import { useAuth0 } from '../../Auth0Provider';
import { Shortlist } from '../HackathonHome/ShortlistProvider';
import { VoteStatus } from '../Results/VoteStatus';
import { Button } from '../shared/Button';
import DismissableMessage from '../shared/DismissableMessage';
import { FooterBar } from '../shared/FooterBar';
import { ProjectCategoryLists } from '../shared/ProjectCategoryLists';
import { ProjectSelectList } from './ProjectSelectList';
import { environment } from '../../environment';

type Props = {
  projects: Project[];
  shortlist: Shortlist;
  results: ResultsDto;
  submitVote(vote: VoteDto): void;
};

export const ProjectSelection: FC<Props> = ({ projects, shortlist, results, submitVote }) => {
  const [teamSelection, setTeamSelection] = useState<number | undefined>();
  const [individualSelection, setIndividualSelection] = useState<number | undefined>();
  const isVotingOpen = !!results && results.isVotingOpen;
  const { isAuthenticated, login } = useAuth0();

  const teamProjects = projects.filter((p) => p.category === ProjectCategory.Team);
  const individualProjects = projects.filter((p) => p.category === ProjectCategory.Individual);

  const canSubmit = environment.singleVoteMode
    ? !!teamSelection && isVotingOpen
    : !!teamSelection && !!individualSelection && isVotingOpen;
  const submit = useCallback(() => {
    if (!canSubmit) return;
    submitVote({
      teamProjectId: teamSelection!,
      individualProjectId: individualSelection,
    });
  }, [teamSelection, individualSelection, submitVote, canSubmit]);

  return (
    <>
      <VoteStatus results={results} />
      {isVotingOpen && (
        <DismissableMessage messageKey="voteInstructions">
          Cast your vote in each category by selecting the projects below.
        </DismissableMessage>
      )}
      <ProjectCategoryLists
        teamContent={
          <ProjectSelectList
            projects={teamProjects}
            selectedProject={teamSelection}
            onSelectionChange={setTeamSelection}
            shortlist={shortlist}
          />
        }
        individualContent={
          <ProjectSelectList
            projects={individualProjects}
            selectedProject={individualSelection}
            onSelectionChange={setIndividualSelection}
            shortlist={shortlist}
          />
        }
      />
      <FooterBar isOpen={canSubmit}>
        {isAuthenticated && (
          <Button onClick={submit} category="success">
            <ConfirmIcon icon={faCheck} />
            Submit Vote
          </Button>
        )}

        {!isAuthenticated && (
          <Button onClick={login} category="primary">
            Log in to vote
          </Button>
        )}
      </FooterBar>
    </>
  );
};

const ConfirmIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
