import React, { FC } from 'react';
import { Reactions } from '../../Api';

export const ReactionEmoji: FC<{ reaction: Reactions }> = ({ reaction }) => {
  const emoji = getEmoji(reaction);
  if (!emoji) return null;
  return (
    <span role="img" aria-label={reaction}>
      {emoji}
    </span>
  );
};

function getEmoji(reaction: Reactions) {
  switch (reaction) {
    case Reactions.Magic:
      return '✨';
    case Reactions.ShipIt:
      return '🚢';
    case Reactions.HeartEyes:
      return '🤩';
    case Reactions.Lol:
      return '🤣';
    case Reactions.PlusOne:
      return '😎';
    case Reactions.PraiseHands:
      return '🙏';
    default:
      return null;
  }
}
