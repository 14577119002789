import styled from '@emotion/styled';
import { faLaptopCode, faSmileBeam, faStar, faUsers, faVoteYea, faYinYang } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { HackathonSummaryDto } from '../../Api';
import { StatsCell } from './StatsCell';

type Props = {
  summary: HackathonSummaryDto | null;
};

const emptyStats: HackathonSummaryDto = {
  totalPeople: 0,
  totalProjects: 0,
  totalReactions: 0,
  totalShortlisted: 0,
  totalVotes: 0
};

export const HackathonSummaryStats: FC<Props> = ({ summary }) => {
  summary = summary || emptyStats;
  return (
    <>
      <Cells>
        <StatsCell title="Total Votes" icon={faVoteYea}>
          {summary!.totalVotes}
        </StatsCell>
        <StatsCell title="Reactions" icon={faSmileBeam}>
          {summary!.totalReactions}
        </StatsCell>
        <StatsCell title="Favourites" icon={faStar}>
          {summary!.totalShortlisted}
        </StatsCell>
        <StatsCell title="Meanings of Life" icon={faYinYang}>
          42
        </StatsCell>
        <StatsCell title="Projects" icon={faLaptopCode}>
          {summary!.totalProjects}
        </StatsCell>
        <StatsCell title="Hackers" icon={faUsers}>
          {summary!.totalPeople}
        </StatsCell>
      </Cells>
    </>
  );
};

const Cells = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1;
    flex-basis: 100%;
    @media (min-width: 400px) {
      flex-basis: calc(50% - 10px);
    }

    @media (min-width: 640px) {
      flex-basis: calc(33% - 10px);
    }

    @media (min-width: 1080px) {
      flex-basis: calc(15% - 10px);
    }
  }
`;
