import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { ProjectCategory, ProjectData } from '../../Api';
import { useApiClient } from '../../hooks/useApiClient';
import AppToolbar from '../shared/AppToolbar';
import { Button, ButtonLink } from '../shared/Button';
import { Page, PageBody } from '../shared/Layout';
import { Instructions } from './Instructions';
import { ProjectEditor } from './ProjectEditor';
import styled from '@emotion/styled';
import { InfosecWarning } from './InfosecWarning';

type RouteParams = { hackathonId: string };

type Props = { hackathonId: number; goToProject(projectId: number): void };

const AddProjectComponent: FC<Props> = ({ hackathonId, goToProject }) => {
  const apiClient = useApiClient();
  const [isCreating, setIsCreating] = useState(false);
  const [project, setProject] = useState<ProjectData>({
    category: ProjectCategory.Team,
    name: '',
    shortDescription: '',
    teamName: ''
  });

  const update = useCallback(
    (update: Partial<ProjectData>) => {
      setProject(current => ({ ...current, ...update }));
    },
    [setProject]
  );

  const isValid = useMemo(() => project.name.length && project.shortDescription.length && project.teamName.length, [
    project
  ]);

  const save = useCallback(() => {
    if (!isValid) return;
    async function save() {
      setIsCreating(true);
      const savedProject = await apiClient.project.create(hackathonId, project);
      if (savedProject) goToProject(savedProject.projectId);
    }
    save();
  }, [isValid, project, goToProject, apiClient.project, hackathonId]);

  return (
    <>
      <Helmet>
        <title>New Project</title>
      </Helmet>
      <Page>
        <AppToolbar hackathonId={hackathonId} />
        <PageBody>
          <Instructions project={project!} />
          <InfosecWarning />
          <ProjectEditor
            project={project!}
            isEditable={!isCreating}
            isUpdating={isCreating}
            hasUpdated={false}
            update={update}
          />
          <Footer>
            <CancelLink category="invisible" to={`/hackathon/${hackathonId}`}>
              Cancel
            </CancelLink>
            <SaveButton category="success" disabled={!isValid} onClick={save}>
              {isCreating ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Create'}
            </SaveButton>
          </Footer>
        </PageBody>
      </Page>
    </>
  );
};

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 10px;

  & > * {
    text-align: center;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const CancelLink = styled(ButtonLink)`
  width: 100px;
`;

const SaveButton = styled(Button)`
  width: 120px;
`;

export const AddProject: FC<RouteComponentProps<RouteParams>> = ({ match, history }) => {
  const goToProject = useCallback(
    projectId => {
      history.push(`/hackathon/${match.params.hackathonId}/project/${projectId}`);
    },
    [history, match.params.hackathonId]
  );

  return <AddProjectComponent hackathonId={Number(match.params.hackathonId)} goToProject={goToProject} />;
};
