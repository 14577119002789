import styled from '@emotion/styled';
import { faPlusCircle, faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { HackathonStage } from '../../Api';
import { ButtonLink } from '../shared/Button';
import theme from '../shared/theme';

type Props = {
  hackathonStage: HackathonStage;
  hackathonId: number;
};

export const AddProjectMessage: FC<Props> = ({ hackathonStage, hackathonId }) => {
  const canAddProjects = hackathonStage === HackathonStage.Planning || hackathonStage === HackathonStage.Hacking;
  if (!canAddProjects) return null;

  return (
    <Container>
      <FontAwesomeIcon icon={faStore} size="2x" color={theme.colors.blue} />
      <Content>
        No more need to talk to the organizers! Now you can <strong>add your own projects</strong>
      </Content>
      <AddLink category="success" to={`/hackathon/${hackathonId}/project`}>
        <ButtonIcon icon={faPlusCircle} color="#fff" />
        Add Project
      </AddLink>
    </Container>
  );
};

const Container = styled.div`
  ${theme.styles.materialPanel}
  background: #fff;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  margin: 0 10px 20px 10px;
  font-size: 14px;
`;

const Content = styled.div`
  grid-column: 2 / 4;
  margin-left: 10px;
  margin-bottom: 15px;
  align-self: center;
  font-size: 12px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const AddLink = styled(ButtonLink)`
  grid-column: 3;
  align-self: end;
`;
