import React, { FC } from 'react';
import { Project } from '../../Api';
import { Shortlist } from '../HackathonHome/ShortlistProvider';
import { Expander } from '../shared/Expander';
import { ProjectButtonsList } from './ProjectButtonsList';

type Props = {
  projects: Project[];
  shortlist: Shortlist;
  selectedProject?: number;
  onSelectionChange(projectId: number): void;
};

export const ProjectSelectList: FC<Props> = ({ projects, shortlist, ...forwardedProps }) => {
  const shortlisted = projects.filter(p => shortlist[p.projectId]);
  const others = projects.filter(p => !shortlist[p.projectId]);

  const hasAnyShortlisted = !!shortlisted.length;
  const hasAnyOthers = !!others.length;
  return (
    <>
      <ProjectButtonsList isShortlisted={true} projects={shortlisted} {...forwardedProps} />
      {hasAnyOthers && (
        <Expander initialIsExpanded={!hasAnyShortlisted} title="Non-Shortlisted" showToggleButton={hasAnyShortlisted}>
          <ProjectButtonsList isShortlisted={false} projects={others} {...forwardedProps} />
        </Expander>
      )}
    </>
  );
};
