import { useEffect, useState, useCallback } from 'react';
import { Hackathon, HackathonStage } from '../Api';
import { useApiClient } from './useApiClient';

export const useHackathon = (hackathonId?: number) => {
  const apiClient = useApiClient();
  const [hackathon, setHackathon] = useState<Hackathon | null>(null);

  useEffect(() => {
    const getHackathon = (hackathonId && apiClient.hackathon.get(hackathonId)) || apiClient.hackathon.getCurrent();

    getHackathon.then(setHackathon);
  }, [setHackathon, hackathonId, apiClient]);

  const setStage = useCallback(
    async (stage: HackathonStage) => {
      if (!hackathon) return;
      const updated = await apiClient.hackathon.setStage(hackathon.hackathonId, stage);
      setHackathon(updated);
    },
    [hackathon, setHackathon, apiClient]
  );

  return { hackathon, setStage };
};
