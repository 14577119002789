import { css } from '@emotion/core';
export const red = '#d9534f';
export const blue = '#337ab7';
export const lightBlue = '#d2deea';
export const midBlue = '#97bbda';
export const green = '#70ab53';
export const lightGreen = '#aacb99';
export const lighterGreen = '#d4e6cb';
export const darkGreen = '#467330';
export const orange = '#ec9622';
export const borderGrey = '#e5e5e5';
export const lightGrey = '#999';

export const materialShadow = css`
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
`;

export const materialPanel = css`
  ${materialShadow};
  padding: 15px;
  border-radius: 2px;
`;

export const slideUp = {
  from: { transform: 'translate3d(0,100%,0)', opacity: 0 },
  enter: { transform: 'translate3d(0,0,0)', opacity: 1 },
  trail: 100
};
export const fadeIn = {
  from: { opacity: 0 },
  opacity: 1
};

export default {
  colors: {
    green,
    lightGreen,
    lighterGreen,
    darkGreen,
    red,
    blue,
    lightBlue,
    midBlue,
    orange,
    borderGrey,
    lightGrey
  },
  styles: {
    materialPanel,
    materialShadow
  },
  transitions: {
    slideUp
  },
  springs: {
    slideUp: { ...slideUp.enter, from: slideUp.from },
    fadeIn
  }
};
