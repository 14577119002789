import styled from '@emotion/styled';
import React, { FC } from 'react';
import { animated, useTransition } from 'react-spring';
import { ReactionResultDto, Reactions } from '../../Api';
import { ReactionEmoji } from '../shared/ReactionEmoji';
import theme from '../shared/theme';

type Props = {
  reactions: ReactionResultDto[];
};

export const ReactionResults: FC<Props> = ({ reactions }) => {
  const maxCount = Math.max(...reactions.map((r) => Object.values(r.reactions)).reduce((a, b) => a.concat(b), []));
  const slideIn = useTransition([0, 1], null, theme.transitions.slideUp)[1].props;

  return (
    <Container style={slideIn}>
      <Table>
        <thead>
          <tr>
            <TitleCell>Reactions</TitleCell>
            {Object.keys(Reactions).map((reaction) => (
              <th key={reaction}>
                <ReactionEmoji reaction={reaction as Reactions} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reactions.map((r) => (
            <tr key={r.projectId}>
              <ProjectNameCell>{r.projectName}</ProjectNameCell>
              {Object.keys(Reactions).map((reaction) => (
                <VoteCell scale={r.reactions[reaction] / maxCount || 0.1} key={reaction}>
                  {r.reactions[reaction] || ''}
                </VoteCell>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled(animated.div)`
  ${theme.styles.materialPanel};
  background: #fff;
  margin: 0 20px;
`;

const Table = styled.table`
  width: 100%;
  text-align: center;

  td,
  th {
    padding: 2px 6px;
  }
  td {
    background: #f5f5f5;
    transition: background-color 0.5s;
  }

  tr:nth-of-type(even) td,
  tr:nth-of-type(even) th {
    background: #eee;
  }
`;

const ProjectNameCell = styled.th`
  text-align: right;
  font-weight: normal;
  font-size: 12px;
`;

const VoteCell = styled.td<{ scale: number }>`
  background: rgba(182, 216, 242, ${(p) => p.scale}) !important;
`;

const TitleCell = styled.th`
  font-weight: 100;
  text-align: left;
`;
