import { ProjectCategory } from '../../Api';
import React, { useState, FC, useCallback } from 'react';
import styled from '@emotion/styled';
import ProjectIcon from '../shared/ProjectIcon';

type Props = {
  defaultValue: ProjectCategory;
  onChange(category: ProjectCategory): void;
  disabled?: boolean;
};

export const ProjectCategoryButton: FC<Props> = ({ defaultValue, onChange, disabled }) => {
  const [value, setValue] = useState(defaultValue);

  const toggle = useCallback(() => {
    const newValue = toggleCategory(value);
    setValue(newValue);
    onChange(newValue);
  }, [value, onChange]);

  const title = disabled ? '' : 'Click to toggle';

  return (
    <IconButton title={title} onClick={toggle} disabled={disabled}>
      <ProjectIcon category={value} />
    </IconButton>
  );
};

const toggleCategory = (category: ProjectCategory) =>
  category === ProjectCategory.Individual ? ProjectCategory.Team : ProjectCategory.Individual;

const IconButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
  font-size: 16px;
`;
