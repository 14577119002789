import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ProjectData } from '../../Api';
import DismissableMessage from '../shared/DismissableMessage';
import ProjectIcon from '../shared/ProjectIcon';
import { environment } from '../../environment';

type Props = { project: ProjectData };

export const Instructions: FC<Props> = ({ project }) => (
  <DismissableMessage messageKey="project-editor-welcome">
    You can change the details for projects that you own.
    <InstructionList>
      {!environment.singleVoteMode && (
        <li>
          Click the <ProjectIcon size="sm" category={project.category} /> icon below to toggle between{' '}
          <strong>Team</strong> and <strong>Individual</strong> projects
        </li>
      )}
      <li>
        Click on the text fields to set the <strong>Project Name</strong>, <strong>Team Name</strong> or{' '}
        <strong>Description</strong>
      </li>
      <li>
        Click on the <strong>Hero Image</strong> to pick an image for your project
      </li>
    </InstructionList>
  </DismissableMessage>
);

const InstructionList = styled.ul`
  padding-left: 15px;
`;
