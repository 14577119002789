import React, { FC } from 'react';
import { useAuth0 } from '../../../Auth0Provider';
import { Button } from '../Button';

export const AuthButton: FC = () => {
  const { loading, isAuthenticated, login, logout } = useAuth0();
  if (loading) return null;

  return (
    <>
      {!isAuthenticated && (
        <Button size="sm" onClick={login}>
          Log in
        </Button>
      )}

      {isAuthenticated && (
        <Button size="sm" onClick={() => logout()}>
          Log out
        </Button>
      )}
    </>
  );
};
