import styled from '@emotion/styled';
import { faSadTear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import AppToolbar from './components/shared/AppToolbar';
import { Page } from './components/shared/Layout';
import theme from './components/shared/theme';

export const NotFound: FC = () => (
  <>
    <Helmet>
      <title>Oh no!</title>
    </Helmet>
    <Page>
      <AppToolbar hackathonId={undefined} />
      <Container>
        <FontAwesomeIcon icon={faSadTear} color={theme.colors.borderGrey} size="6x" />
        <Header>Oh no!</Header>
        <Message>
          I can't find that page. Check out the <Link to="/">Home Page</Link> and see if you can find what you're after
        </Message>
      </Container>
    </Page>
  </>
);

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25%;
  padding: 40px;
`;

const Header = styled.h2`
  margin-bottom: 0;
`;

const Message = styled.p`
  max-width: 400px;
  font-size: 12px;
  text-align: center;
`;
