import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ProjectCategory } from '../../Api';
import { environment } from '../../environment';
import { MinimalProject } from '../Results';
import theme from '../shared/theme';
import { WinningProject } from './WinningProject';

type Props = {
  hackathonId: number;
  teamWinner?: MinimalProject;
  individualWinner?: MinimalProject;
};

export const Winners: FC<Props> = ({ hackathonId, teamWinner, individualWinner }) => {
  return (
    <ConfirmationContainer>
      <Title>Winners</Title>
      <Columns>
        <WinningProject hackathonId={hackathonId} category={ProjectCategory.Team} project={teamWinner} />
        {!environment.singleVoteMode && (
          <WinningProject hackathonId={hackathonId} category={ProjectCategory.Individual} project={individualWinner} />
        )}
      </Columns>
    </ConfirmationContainer>
  );
};

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.borderGrey};
`;

const Title = styled.h3`
  text-align: center;
  border-bottom: 1px solid ${theme.colors.borderGrey};
  margin: 0;
  padding: 0 0 10px 0;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 480px) {
    flex-direction: row;
    & > * {
      flex: 1;
    }
  }
`;
