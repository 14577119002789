import styled from '@emotion/styled';
import { faEye, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { HackathonStage } from '../../Api';
import { ButtonLink } from '../shared/Button';
import theme from '../shared/theme';

type Props = {
  hackathonStage: HackathonStage;
};

export const LiveDemoMessage: FC<Props> = ({ hackathonStage }) => {
  const isLive = hackathonStage === HackathonStage.Demo;
  if (!isLive) return null;

  return (
    <Container>
      <FontAwesomeIcon icon={faLaptopCode} size="2x" color={theme.colors.blue} />
      <Content>The hackathon demo is live!</Content>
      <AddLink category="success" to="/live">
        <ButtonIcon icon={faEye} color="#fff" />
        Follow Along
      </AddLink>
    </Container>
  );
};

const Container = styled.div`
  ${theme.styles.materialPanel}
  background: #fff;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  margin: 0 10px 20px 10px;
  font-size: 14px;
`;

const Content = styled.div`
  grid-column: 2 / 4;
  margin-left: 10px;
  margin-bottom: 15px;
  align-self: center;
  font-size: 12px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const AddLink = styled(ButtonLink)`
  grid-column: 3;
  align-self: end;
`;
