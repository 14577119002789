import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import { HackathonStage } from '../../Api';
import { Button } from '../shared/Button';
import theme from '../shared/theme';

type Props = { currentStage: HackathonStage; setStage(stage: HackathonStage): void };

export const HackathonStageEditor: FC<Props> = ({ currentStage, setStage }) => {
  const availableStages = Object.keys(HackathonStage);
  const selectedStage = availableStages.indexOf(currentStage);
  const previousStage = availableStages[selectedStage - 1] as HackathonStage;
  const nextStage = availableStages[selectedStage + 1] as HackathonStage;

  const nextStageText = nextStage === HackathonStage.Finished ? 'Mark as' : 'Start';

  const back = useCallback(() => setStage(previousStage), [previousStage, setStage]);
  const next = useCallback(() => setStage(nextStage), [nextStage, setStage]);
  return (
    <Container>
      <Stage>
        {previousStage && (
          <BackButton size="sm" onClick={back}>
            <Label>Back to</Label> <strong>{previousStage}</strong>
          </BackButton>
        )}
      </Stage>
      <Stage>
        <CurrentStage>
          <strong>{currentStage}</strong>
          <Label>Current Stage</Label>
        </CurrentStage>
      </Stage>
      <Stage>
        {nextStage && (
          <NextButton size="sm" onClick={next} category="primary">
            <PrimaryLabel>{nextStageText}</PrimaryLabel>
            <strong>{nextStage}</strong>
          </NextButton>
        )}
      </Stage>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > div:nth-of-type(2) {
    order: 0;
    flex-basis: 100%;
  }

  @media (min-width: 480px) {
    & > div:nth-of-type(2) {
      order: 1;
      flex-basis: auto;
    }
  }
`;

const Stage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: space-between;
  margin-bottom: 10px;
  order: 1;

  @media (min-width: 480px) {
    align-items: center;
    justify-content: center;
    & button {
      width: 100px;
    }
  }
`;

const Label = styled.span`
  color: ${theme.colors.lightGrey};
  font-size: 10px;
  display: block;
`;

const BackButton = styled(Button)`
  margin-right: 5px;
  @media (min-width: 480px) {
    margin: 0;
  }
`;

const NextButton = styled(Button)`
  margin-left: 5px;
  @media (min-width: 480px) {
    margin: 0;
  }
`;

const PrimaryLabel = styled.span`
  color: #eee;
  font-size: 10px;
  display: block;
`;

const CurrentStage = styled.div`
  ${theme.styles.materialPanel};
  background: #fff;
  text-align: center;
  flex-basis: 100%;

  @media (min-width: 480px) {
    font-size: 1.2em;
    width: 150px;
  }
`;
