import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHackathon } from '../../hooks/useHackathon';
import AppToolbar from '../shared/AppToolbar';
import DismissableMessage from '../shared/DismissableMessage';
import { Page, PageBody, PageTitle } from '../shared/Layout';
import Require from '../shared/Require';
import { HackathonStageEditor } from './HackathonStageEditor';
import { DemoSlotEditor } from './DemoSlotEditor';
import { DemoSlotProvider } from '../../hooks/useDemoSlot';

export const AdminHome: FC = () => {
  const { hackathon, setStage } = useHackathon();

  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Page>
        <AppToolbar hackathonId={hackathon ? hackathon.hackathonId : undefined} />
        <PageBody>
          <Require required={hackathon}>
            {() => (
              <>
                <PageTitle>{hackathon!.name} Admin</PageTitle>
                <DismissableMessage messageKey="admin:welcome" icon={<FontAwesomeIcon icon={faKey} />}>
                  Welcome, <strong>admin</strong>. Be careful where you tread. Here be dragons (and very little testing)
                </DismissableMessage>
                <HackathonStageEditor currentStage={hackathon!.currentStage} setStage={setStage} />
                <DemoSlotProvider hackathonId={hackathon!.hackathonId}>
                  <DemoSlotEditor hackathonId={hackathon!.hackathonId} />
                </DemoSlotProvider>
              </>
            )}
          </Require>
        </PageBody>
      </Page>
    </>
  );
};
