import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Auth0Provider } from './Auth0Provider';
import AdminHome from './components/AdminHome';
import HackathonHome from './components/HackathonHome';
import ProjectHome from './components/ProjectHome';
import Results from './components/Results';
import VotingHome from './components/VotingHome';
import { environment } from './environment';
import { PrivateRoute } from './PrivateRoute';
import { AddProject } from './components/ProjectHome/AddProjectHome';
import { NotFound } from './NotFound';
import { AuthRedirect } from './components/AuthRedirect';
import LiveHome from './components/LiveHome';
import { ChatHome } from './components/Chat/ChatHome';

const AppContainer = styled.div`
  background: #f5f5f5;
  color: #202020;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

const App: FC = () => (
  <Auth0Provider
    domain={environment.domain}
    audience={environment.audience}
    client_id={environment.clientId}
    redirect_uri={window.location.origin}
  >
    <AppContainer>
      <Helmet titleTemplate="%s | Appathon" defaultTitle="Appathon" />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HackathonHome} />
          <PrivateRoute exact path="/live" component={LiveHome} />
          <Route exact path="/result" component={Results} />
          <Redirect from="/results" to="/result" />
          <Route exact path="/hackathon/:hackathonId" component={HackathonHome} />
          <PrivateRoute exact path="/hackathon/:hackathonId/live" component={LiveHome} />
          <Route exact path="/hackathon/:hackathonId/project/:projectId" component={ProjectHome} />
          <PrivateRoute exact path="/hackathon/:hackathonId/project" component={AddProject} />
          <Route exact path="/hackathon/:hackathonId/result" component={Results} />
          <Redirect exact from="/hackathon/:hackathonId/results" to="/hackathon/:hackathonId/result" />
          <PrivateRoute exact path="/hackathon/:hackathonId/vote" component={VotingHome} />
          <PrivateRoute exact path="/admin" component={AdminHome} />
          <PrivateRoute exact path="/hackathon/:hackathonId/chat" component={ChatHome} />
          <Route exact path="/signin" component={AuthRedirect} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </AppContainer>
  </Auth0Provider>
);

export default App;
