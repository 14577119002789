import styled from '@emotion/styled';
import { faBars, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Project } from '../../Api';
import { Button } from '../shared/Button';

type Props = {
  project: Project;
  addDemo(projectId: number): void;
  cancelDemo(projectId: number): void;
};

export const DemoSlotRow: FC<Props> = ({ project, addDemo, cancelDemo }) => {
  const isDemoScheduled = project.demoSlot !== undefined;
  return (
    <Row>
      <DragHandleColumn>
        {isDemoScheduled && (
          <DragHandle>
            <DragIcon icon={faBars} />
          </DragHandle>
        )}
      </DragHandleColumn>
      <SlotColumn>{isDemoScheduled ? project.demoSlot! + 1 : '-'}</SlotColumn>
      <td>{project.name}</td>
      <ButtonColumn>
        {isDemoScheduled && (
          <Button size="sm" category="danger" onClick={() => cancelDemo(project.projectId)} title="Cancel Demo">
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
        {isDemoScheduled || (
          <Button size="sm" category="success" onClick={() => addDemo(project.projectId)} title="Schedule Demo">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </ButtonColumn>
    </Row>
  );
};

const Row = styled.tr`
  background: rgba(255, 255, 255, 0.6);
  padding: 5px;
  &.sorting button {
    display: none;
  }
`;

const DragHandleColumn = styled.td`
  width: 30px;
  text-align: center;
`;

const DragHandleTarget = styled.div`
  padding: 5px;
  margin: -5px;
  background: transparent;
`;
const DragHandle = SortableHandle(DragHandleTarget);

const DragIcon = styled(FontAwesomeIcon)`
  transform: scale(1.3);
`;

const SlotColumn = styled.td`
  width: 30px;
  text-align: center;
`;

const ButtonColumn = styled.td`
  width: 50px;
  text-align: center;
  padding-right: 0 !important;
  padding-left: 0 !important;
`;
