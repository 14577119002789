import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Project } from '../../Api';
import ProjectIcon from '../shared/ProjectIcon';
import theme from '../shared/theme';
import { ProjectHeroImage } from './ProjectHeroImage';
import { ShortlistButton } from './ShortlistButton';

type Props = {
  project: Project;
};

export const ProjectPanel: FC<Props> = ({ project }) => (
  <ProjectLink to={`/hackathon/${project.hackathonId}/project/${project.projectId}`}>
    <PanelContainer>
      <IconContainer>
        <ProjectIcon category={project.category} />
      </IconContainer>
      <ProjectContent>
        <Title>{project.name}</Title>
        <Columns>
          <LeftColumn>
            <Description>{project.shortDescription}</Description>
            <ShortlistButtonContainer>
              <ShortlistButton projectId={project.projectId} />
            </ShortlistButtonContainer>
          </LeftColumn>
          <HeroImageContainer>
            <ProjectHeroImage project={project} />
            <TeamName>{project.teamName}</TeamName>
          </HeroImageContainer>
        </Columns>
      </ProjectContent>
    </PanelContainer>
  </ProjectLink>
);

const ProjectLink = styled(Link)``;

const PanelContainer = styled.div`
  padding: 20px;
  position: relative;
  height: 200px;
  display: flex;
  flex-direction: column;
`;

const ProjectContent = styled.div`
  ${theme.styles.materialPanel}
  top: 25px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  position: absolute;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const IconContainer = styled.div`
  top: 10px;
  left: 20px;
  position: absolute;
  z-index: 1;
`;

const HeroImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
`;

const Title = styled.h2`
  margin-left: 65px;
  padding-bottom: 3px;
  display: block;
  font-weight: normal;
  font-size: 14px;
  margin-top: 0;
  border-bottom: 1px solid ${theme.colors.borderGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  transition: color 0.2s;
`;

const TeamName = styled.h3`
  font-weight: normal;
  font-size: 12px;
  color: #404040;
  margin: 10px 0 0 0;
  text-align: center;
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Columns = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: calc(100% - 34px);
`;

const Description = styled.p`
  color: #202020;
  font-size: 16px;
  font-weight: lighter;
  flex: 1;
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  &::after {
    content: '';
    height: 1.5em;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent, white);
  }
`;

const ShortlistButtonContainer = styled.div`
  height: 50px;
`;

const LeftColumn = styled.div`
  width: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`;
