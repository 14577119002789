import styled from '@emotion/styled';
import { faTrophy, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { HackathonStage } from '../../Api';
import { ButtonLink } from '../shared/Button';
import theme from '../shared/theme';

type Props = {
  hackathonStage: HackathonStage;
  hackathonId: number;
};

export const GoToVotingMessage: FC<Props> = ({ hackathonStage, hackathonId }) => {
  if (hackathonStage !== HackathonStage.Vote) return null;

  return (
    <Container>
      <FontAwesomeIcon icon={faTrophy} size="3x" color={theme.colors.orange} />
      <Content>
        The vote is open! Better hurry and <strong>cast your vote!</strong>
      </Content>
      <VoteLink category="primary" to={`/hackathon/${hackathonId}/vote`}>
        <ButtonIcon icon={faVoteYea} color="#fff" />
        Cast your vote
      </VoteLink>
    </Container>
  );
};

const Container = styled.div`
  ${theme.styles.materialPanel}
  background: #fff;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  margin: 0 10px 20px 10px;
  font-size: 14px;
`;

const Content = styled.div`
  grid-column: 2 / 4;
  margin-left: 10px;
  margin-bottom: 15px;
  align-self: center;
  font-size: 12px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const VoteLink = styled(ButtonLink)`
  grid-column: 3;
  align-self: end;
`;
