import { HubConnectionState } from '@microsoft/signalr';
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { DemoStateDto, Reactions } from '../Api';
import { useAuth0 } from '../Auth0Provider';
import { environment } from '../environment';
import { useSignalrConnection } from '../useSignalrConnection';
import { useApiClient } from './useApiClient';

const initialDemoSlot: DemoStateDto = {
  isComplete: false,
  isLive: false
};

type UserReactions = Partial<Record<Reactions, boolean>>;

type DemoSlotContextState = {
  demoSlot: DemoStateDto;
  canReact: boolean;
  currentUserReactions: UserReactions;
  toggleReaction(reaction: Reactions): void;
  state: HubConnectionState;
};

function noop() {}

const DemoSlotContext = createContext<DemoSlotContextState>({
  canReact: false,
  currentUserReactions: {},
  demoSlot: initialDemoSlot,
  toggleReaction: noop,
  state: HubConnectionState.Disconnected
});

export const useDemoSlot = () => useContext(DemoSlotContext);

type ProviderProps = { hackathonId?: number };
export const DemoSlotProvider: FC<ProviderProps> = ({ hackathonId, children }) => {
  const [demoSlot, setDemoSlot] = useState(initialDemoSlot);
  const [currentUserReactions, setCurrentUserReactions] = useState<UserReactions>({});
  const apiClient = useApiClient();
  const { isAuthenticated } = useAuth0();
  const canReact = isAuthenticated && !!demoSlot.project;

  const hubUrl = `${environment.apiUrl}/hubs/demo?hackathonId=${hackathonId || ''}`;
  const { state } = useSignalrConnection(hubUrl, {
    updateDemoState: setDemoSlot
  });

  const toggleReaction = useCallback(
    async (reaction: Reactions) => {
      if (!canReact) return;
      if (!demoSlot.project) return;

      const reactions = await apiClient.reaction.toggleReaction(
        demoSlot.project.hackathonId,
        demoSlot.project.projectId,
        reaction
      );
      setCurrentUserReactions(reactions.reactions as UserReactions);
    },
    [apiClient.reaction, canReact, demoSlot.project]
  );

  useEffect(() => {
    async function load() {
      if (!demoSlot.project) return;
      if (!isAuthenticated) return;
      const reactions = await apiClient.reaction.getReactions(demoSlot.project.hackathonId, demoSlot.project.projectId);
      setCurrentUserReactions(reactions.reactions as UserReactions);
    }
    load();
  }, [apiClient.reaction, apiClient.vote, demoSlot.project, isAuthenticated]);

  return (
    <DemoSlotContext.Provider value={{ demoSlot, currentUserReactions, canReact, toggleReaction, state }}>
      {children}
    </DemoSlotContext.Provider>
  );
};
