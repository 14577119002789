import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import spinner from './Loading.svg';

type Size = 'sm' | 'md' | 'lg';
const sizes: { [key: string]: number } = {
  sm: 16,
  md: 32,
  lg: 64
};

const fadeIn = keyframes`
  0% { opacity: 0; }
  70% { opacity: 0; }
  100% { opacity: 1; }
`;

type Props = { size?: Size };

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Spinner = styled.img<Props>`
  animation: ${fadeIn} 1s;
  height: ${(props: Props) => sizes[props.size || 'md']}px;
  width: ${(props: Props) => sizes[props.size || 'md']}px;
`;

export const Loading: FC<Props> = props => (
  <SpinnerContainer>
    <Spinner src={spinner} alt="Loading" {...props} />
  </SpinnerContainer>
);
