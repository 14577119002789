import React, { FC } from 'react';
import { ResultsDto } from '../../Api';
import { ProjectCategoryLists } from '../shared/ProjectCategoryLists';
import { NoResultsPlaceholder } from './NoResultsPlaceholder';
import { ResultsTable } from './ResultsTable';

type Props = {
  results: ResultsDto;
};

export const ResultsLists: FC<Props> = ({ results }) => {
  const resultsReceived = results.teamProjects!.length || results.individualProjects!.length;
  if (!resultsReceived) return <NoResultsPlaceholder />;

  const redactNames = results.hasVotingStarted && results.isVotingOpen;
  return (
    <ProjectCategoryLists
      teamContent={<ResultsTable results={results.teamProjects!} redactNames={redactNames} />}
      individualContent={<ResultsTable results={results.individualProjects!} redactNames={redactNames} />}
    />
  );
};
