import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import theme from '../theme';
import { AuthButton } from './AuthButton';
import { Profile } from './Profile';
import { useAuth0 } from '../../../Auth0Provider';
import { ButtonLink } from '../Button';

type Props = { hackathonId: number | undefined };

export const AppToolbar: FC<Props> = ({ children, hackathonId }) => {
  const homeUrl = hackathonId ? `/hackathon/${hackathonId}` : `/`;
  const { isAdmin } = useAuth0();
  return (
    <ToolbarContainer>
      <TitleLink to={homeUrl}>
        <Logo src="/img/icon/favicon-32x32.png" alt="Appathon" />
        Appathon
      </TitleLink>
      <Content>{children}</Content>
      <Right>
        {isAdmin && (
          <ButtonLink to="/admin" size="sm" category="primary">
            Admin
          </ButtonLink>
        )}
        <AuthButton />
        <Profile />
      </Right>
    </ToolbarContainer>
  );
};

const ToolbarContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0 10px;
  overflow: hidden;
`;

const TitleLink = styled(Link)`
  font-size: 22px;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  color: #bbb;
  margin: 0;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.2s;
  display: flex;
  align-items: center;

  &:hover {
    color: ${theme.colors.orange};
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  margin-left: 10px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 10px;
  }
`;

const Logo = styled.img`
  ${theme.styles.materialPanel}
  padding: 0;
  margin-right: 10px;
`;
