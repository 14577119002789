import styled from '@emotion/styled';
import { faMedal, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import theme from '../shared/theme';

type Props = { position: number };

export const PrizeIcon: FC<Props> = ({ position }) => {
  if (position === 1) return <Icon color={theme.colors.orange} icon={faTrophy} size="2x" />;

  if (position === 2) return <Icon color="#999" icon={faMedal} size="2x" />;

  if (position === 3) return <Icon color="#6b1515" icon={faMedal} size="2x" />;

  return <NoIcon />;
};

const Icon = styled(FontAwesomeIcon)`
  &.svg-inline--fa {
    width: 40px;
  }
`;

const NoIcon = styled.div`
  width: 40px;
`;
