interface Environment {
  isDevelopment: boolean;
  apiUrl: string;
  domain: string;
  clientId: string;
  audience: string;
  singleVoteMode: boolean;
}

export const environment: Environment = {
  isDevelopment: process.env.NODE_ENV === 'development',
  apiUrl: process.env.REACT_APP_API_URL!,
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID!,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
  singleVoteMode: true,
};
