import { HubConnectionBuilder, IRetryPolicy } from '@microsoft/signalr';
import { useEffect, useMemo, useState } from 'react';

const retryConstantly: IRetryPolicy = { nextRetryDelayInMilliseconds: () => 1000 };

export function useSignalrConnection(hubUrl: string, eventHandlers: Record<string, any>) {
  const connection = useMemo(
    () =>
      new HubConnectionBuilder()
        .withAutomaticReconnect(retryConstantly)
        .withUrl(hubUrl)
        .build(),
    [hubUrl]
  );
  const [state, setConnectionState] = useState(connection.state);

  useEffect(() => {
    const handler = () => setConnectionState(connection.state);

    connection.start().then(() => {
      connection.onclose(handler);
      connection.onreconnected(handler);
      connection.onreconnecting(handler);
      handler();
    });

    return () => {
      connection.stop();
    };
  }, [connection]);

  useEffect(() => {
    if (!eventHandlers || !connection) return;
    Object.keys(eventHandlers).forEach(eventName => connection.on(eventName, eventHandlers[eventName]));
    return () => Object.keys(eventHandlers).forEach(eventName => connection.off(eventName, eventHandlers[eventName]));
  }, [connection, eventHandlers]);

  return { connection, state };
}
