import styled from '@emotion/styled';
import { faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { Button } from '../Button';
import { readFileAsDataUrl, resizeImage } from './imageUtils';

type Props = {
  onChange(url: string): void;
};

export const FileUpload: FC<Props> = ({ onChange }) => {
  const [preview, setPreview] = useState<string | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const triggerUpload = useCallback(() => {
    if (!fileInput.current) return;
    setIsUploading(true);
    fileInput.current.click();
  }, []);

  const setImage = useCallback(
    async ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      if (!currentTarget.files || !currentTarget.files.length) return;
      const file = currentTarget.files[0];

      const dataUrl = await readFileAsDataUrl(file);
      setPreview(await resizeImage(dataUrl, 1920));
      onChange(await resizeImage(dataUrl, 256));

      setIsUploading(false);
    },
    [onChange, setPreview, setIsUploading]
  );

  const hasPreview = !!preview;
  const buttonSize = hasPreview ? 'md' : 'lg';
  const buttonText = hasPreview ? 'Upload Another' : 'Upload Image';

  return (
    <Container>
      {preview && <PreviewImage url={preview} />}
      <UploadButton category="primary" onClick={triggerUpload} size={buttonSize}>
        {isUploading && <FontAwesomeIcon icon={faSpinner} spin />}
        {!isUploading && (
          <>
            <UploadIcon icon={faUpload} />
            {buttonText}
          </>
        )}
      </UploadButton>
      <HiddenInput type="file" ref={fileInput} accept="image/*" capture="user" onChange={setImage} />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const PreviewImage = styled.div<{ url: string }>`
  flex: 1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${p => p.url});
  margin-bottom: 10px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const UploadButton = styled(Button)`
  width: 220px;
  align-self: center;
`;

const UploadIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
