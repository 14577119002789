import styled from '@emotion/styled';
import React, { FC } from 'react';
import { animated, useTransition } from 'react-spring';
import { Project } from '../../Api';
import theme from '../shared/theme';
import { ProjectPanel } from './ProjectPanel';

type Props = { projects: Project[] };
export const ProjectsOverview: FC<Props> = ({ projects }) => {
  const transitions = useTransition(projects, p => p.projectId, theme.transitions.slideUp);

  return (
    <ProjectsContainer>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <ProjectPanel project={item} />
        </animated.div>
      ))}
    </ProjectsContainer>
  );
};

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 100px;
  & > * {
    flex-basis: 100%;
  }

  @media (min-width: 768px) {
    & > * {
      flex-basis: 50%;
    }
  }

  @media (min-width: 1024px) {
    & > * {
      flex-basis: 33%;
    }
  }

  @media (min-width: 1400px) {
    & > * {
      flex-basis: 25%;
    }
  }
`;
