import React from 'react';

const isDevelopment = process.env.NODE_ENV === 'development';
if (isDevelopment) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  console.log('Enabling whyDidYouRender');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
  });
}

export default { tracking: isDevelopment };
