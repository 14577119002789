import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { useHackathon } from '../../hooks/useHackathon';
import { useResults } from '../../hooks/useResults';
import AppToolbar from '../shared/AppToolbar';
import { Page, PageBody, PageTitle } from '../shared/Layout';
import Require from '../shared/Require';
import { ResultsLists } from './ResultsLists';
import { VoteStatus } from './VoteStatus';
import { ReactionResults } from './ReactionResults';
import styled from '@emotion/styled';
import { Clapper } from '../../clapApp/Clapper';

type RouteParams = { hackathonId?: string };

type Props = { hackathonId?: number };

const ResultsComponent: FC<Props> = ({ hackathonId }) => {
  const { hackathon } = useHackathon(hackathonId);
  const { results } = useResults(hackathonId);

  return (
    <>
      <Helmet>
        <title>{(hackathon && `${hackathon.name} Vote`) || 'Vote'}</title>
      </Helmet>
      <Page>
        <AppToolbar hackathonId={hackathonId} />
        <PageBody>
          <Require required={results && hackathon}>
            {() => (
              <>
                <PageTitle>{hackathon!.name} Results</PageTitle>
                <VoteStatus results={results!} />
                <ResultsLists results={results!} />
                {results!.reactions && <ReactionResults reactions={results!.reactions} />}
                <ClapContainerPadding>
                  <FixedClapContainer>
                    <Clapper hackathonId={hackathon!.hackathonId} />
                  </FixedClapContainer>
                </ClapContainerPadding>
              </>
            )}
          </Require>
        </PageBody>
      </Page>
    </>
  );
};

const ClapContainerPadding = styled.div`
  height: 150px;
`;

const FixedClapContainer = styled.div`
  transform: scale(2.5);
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;
`;

export const Results: FC<RouteComponentProps<RouteParams>> = ({ match }) => (
  <ResultsComponent hackathonId={Number(match.params.hackathonId)} />
);
