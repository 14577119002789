import styled from '@emotion/styled';
import React, { FC, useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import { HeroImage } from '../HackathonHome/ProjectHeroImage';
import { Button } from '../shared/Button';
import ImagePicker from '../shared/ImagePicker';
import theme from '../shared/theme';

type Props = {
  heroImageUrl: string | undefined;
  onChange(heroImageUrl: string | undefined): void;
  disabled?: boolean;
};

export const HeroImagePickerModal: FC<Props> = ({ heroImageUrl, onChange, disabled }) => {
  const [url, setUrl] = useState(heroImageUrl || '');
  const [isOpen, setIsOpen] = useState(false);

  const confirmChange = useCallback(() => {
    setIsOpen(false);
    onChange(url);
  }, [url, setIsOpen, onChange]);

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const cancel = useCallback(() => {
    setIsOpen(false);
    setUrl(heroImageUrl || '');
  }, [setIsOpen, setUrl, heroImageUrl]);

  return (
    <>
      <OpenButton onClick={open} title="Click to select an image" disabled={disabled}>
        <HeroImage url={url} size="lg" />
      </OpenButton>
      <ReactModal isOpen={isOpen} style={modalStyle} shouldCloseOnEsc shouldCloseOnOverlayClick>
        <Container>
          <Header>Hero Image</Header>
          <PreviewContainer>
            <HeroImage url={url} size="xl" />
          </PreviewContainer>
          <ImagePicker imageUrl={url} onChange={setUrl} />
          <Footer>
            <Button onClick={cancel} size="md" category="transparent">
              Cancel
            </Button>
            <Button onClick={confirmChange} size="md" category="success">
              Save Changes
            </Button>
          </Footer>
        </Container>
      </ReactModal>
    </>
  );
};

const OpenButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
`;

const Header = styled.h3`
  margin: 0;
  font-weight: 100;
  border-bottom: 1px solid ${theme.colors.borderGrey};
  margin-bottom: 10px;
`;

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 5px;
  margin-top: 5px;
  margin-bottom: 3px;
  border-top: 1px solid ${theme.colors.borderGrey};

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const modalStyle: ReactModal.Styles = {
  overlay: {
    background: 'rgba(0,0,0,0.3)',
    zIndex: 1000,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    top: '20px',
    left: '20px',
    bottom: '20px',
    right: '20px',
  },
};
