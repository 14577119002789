import styled from '@emotion/styled';
import React, { FC } from 'react';
import theme from '../shared/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { projectImageUrl } from '../../utils';

type Size = 'sm' | 'md' | 'lg' | 'xl';
type Props = {
  size?: Size;
  project: { projectId: number; hackathonId: number };
};

export const ProjectHeroImage: FC<Props> = ({ project, size }) => (
  <HeroImage size={size || 'lg'} url={projectImageUrl(project.hackathonId, project.projectId)} />
);

export const HeroImage: FC<{ url: string; size: Size }> = ({ url, size }) => (
  <CircularBadge size={size}>
    {url ? (
      <HeroImageDiv src={url} />
    ) : (
      <DefaultHeroImage size={size}>
        <FontAwesomeIcon icon={faCamera} />
      </DefaultHeroImage>
    )}
  </CircularBadge>
);

const dimensions: any = {
  sm: '30px',
  md: '75px',
  lg: '100px',
  xl: '150px',
};

const CircularBadge = styled.div<{ size: Size }>`
  ${theme.styles.materialPanel}
  border-radius: 50%;
  background: #fff;
  height: ${(p) => dimensions[p.size]};
  width: ${(p) => dimensions[p.size]};
  max-width: ${(p) => dimensions[p.size]};
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  & > * {
    flex: 1;
  }
`;

const HeroImageDiv = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

const fontSizes: any = {
  sm: 20,
  md: 30,
  lg: 50,
  xl: 70,
};

const DefaultHeroImage = styled.div<{ size: Size }>`
  font-size: ${(p) => fontSizes[p.size]}px;
  color: #ccc;
  background: ${theme.colors.borderGrey};
  display: flex;
  align-items: center;
  justify-content: center;
`;
