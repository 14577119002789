import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { DemoSlotProvider } from '../../hooks/useDemoSlot';
import { useHackathon } from '../../hooks/useHackathon';
import { ShortlistProvider } from '../HackathonHome/ShortlistProvider';
import AppToolbar from '../shared/AppToolbar';
import { HackathonContext } from '../shared/HackathonContext';
import { Page } from '../shared/Layout';
import Require from '../shared/Require';
import Chat from '../Chat';
import { LiveDemoStatus } from './LiveDemoStatus';

export const LiveHome: FC = () => {
  const { hackathon } = useHackathon();

  return (
    <>
      <Helmet>{hackathon && <title>{hackathon.name} Live</title>}</Helmet>
      <Page>
        <AppToolbar hackathonId={hackathon ? hackathon.hackathonId : undefined} />
        <PageContainer>
          <Require required={hackathon}>
            {() => (
              <HackathonContext.Provider value={{ hackathonId: hackathon!.hackathonId, hackathon }}>
                <ShortlistProvider hackathonId={hackathon!.hackathonId}>
                  <DemoSlotProvider hackathonId={hackathon!.hackathonId}>
                    <LiveDemoStatus />
                    <Chat hackathonId={hackathon!.hackathonId} />
                  </DemoSlotProvider>
                </ShortlistProvider>
              </HackathonContext.Provider>
            )}
          </Require>
        </PageContainer>
      </Page>
    </>
  );
};

const PageContainer = styled.div`
  position: absolute;
  top: 50px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
`;
