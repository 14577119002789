import styled from '@emotion/styled';
import { faImage, faLink, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Tab, Tabs } from '../Tabs';
import { FileUpload } from './FileUpload';
import { ImageSearch } from './ImageSearch';
import { ManualUrlEntry } from './ManualUrlEntry';

type Props = {
  imageUrl: string;
  onChange(imageUrl: string): void;
};

export const ImagePicker: FC<Props> = ({ imageUrl, onChange }) => (
  <FixedHeightTabs>
    <FixedHeightTab tabKey="search" title={<FontAwesomeIcon icon={faSearch} />}>
      <ImageSearch onChange={onChange} imageUrl={imageUrl} />
    </FixedHeightTab>
    <FixedHeightTab tabKey="file" title={<FontAwesomeIcon icon={faImage} />}>
      <FileUpload onChange={onChange} />
    </FixedHeightTab>
    <FixedHeightTab tabKey="manual" title={<FontAwesomeIcon icon={faLink} />}>
      <ManualUrlEntry onChange={onChange} imageUrl={imageUrl} />
    </FixedHeightTab>
  </FixedHeightTabs>
);

const FixedHeightTabs = styled(Tabs)`
  flex: 1;
  overflow: hidden;
`;

const FixedHeightTab = styled(Tab)`
  overflow: hidden;
`;
