import styled from '@emotion/styled';
import React, { FC } from 'react';
import theme from './theme';

type Props = {
  user?: {
    displayName?: string;
    picture?: string;
  };
};

export const ProfileImage: FC<Props> = ({ user }) => {
  if (!user || !user.displayName) return null;

  return <Image src={user.picture} title={user.displayName} />;
};

const Image = styled.img`
  ${theme.styles.materialPanel}
  padding: 0;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;
`;
