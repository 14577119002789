import styled from '@emotion/styled';
import React, { FC } from 'react';
import { animated, useSpring } from 'react-spring';
import theme from './theme';

type Props = { isOpen: boolean };

export const FooterBar: FC<Props> = ({ isOpen, children }) => {
  const slideUp = useSpring({
    transform: `scaleY(${isOpen ? 1 : 0})`,
    opacity: isOpen ? 1 : 0
  });
  return <ToastContainer style={slideUp}>{children}</ToastContainer>;
};

const ToastContainer = styled(animated.div)`
  ${theme.styles.materialPanel}
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform-origin: bottom;
`;
