import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import React, { CSSProperties, FC, MouseEvent } from 'react';
import theme from './theme';
import { LinkProps, Link } from 'react-router-dom';

type ButtonCategory = 'transparent' | 'success' | 'primary' | 'danger' | 'invisible';
type ButtonSize = 'sm' | 'md' | 'lg';

type Props = {
  onClick(): void;
  category?: ButtonCategory;
  size?: ButtonSize;
  title?: string;
  disabled?: boolean;
  style?: CSSProperties;
};

const onClickWithoutDefault = (onClick: () => void) => (event: MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  onClick();
};

export const Button: FC<Props> = ({ children, category, size, onClick, ...buttonProps }) => (
  <ButtonWrapper
    {...buttonProps}
    onClick={onClickWithoutDefault(onClick)}
    category={category || 'transparent'}
    size={size || 'md'}
  >
    {children}
  </ButtonWrapper>
);

type ButtonLinkProps = LinkProps & {
  category?: ButtonCategory;
  size?: ButtonSize;
};

export const ButtonLink: FC<ButtonLinkProps> = ({ children, category, size, ...linkProps }) => (
  <LinkWrapper {...linkProps} category={category || 'transparent'} size={size || 'md'}>
    {children}
  </LinkWrapper>
);

type StyleProps = { category: ButtonCategory; size: ButtonSize };

const buttonStyle = css`
  ${theme.styles.materialPanel}
  background: none;
  border: none;
  color: #202020;
  cursor: pointer;

  transition-duration: 0.3s;
  transition-property: background-color, color, opacity;
`;

const ButtonWrapper = styled.button<StyleProps>`
  ${buttonStyle}
  ${props => categories[props.category]}
  ${props => sizes[props.size]}

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

const LinkWrapper = styled(Link)<StyleProps>`
  ${buttonStyle}
  text-decoration: none;
  display: inline-block;
  ${props => categories[props.category]}
  ${props => sizes[props.size]}
`;

const categories: { [key: string]: SerializedStyles } = {
  transparent: css`
    background: transparent;
    color: #202020;

    &:hover:not(:focus):not(:active) {
      background-color: ${theme.colors.blue};
      color: #fff;
    }
  `,
  success: css`
    background: ${theme.colors.green};
    color: #fff;

    &:hover {
      opacity: 0.9;
    }
  `,
  primary: css`
    background: ${theme.colors.blue};
    color: #fff;

    &:hover {
      opacity: 0.9;
    }
  `,
  danger: css`
    background: ${theme.colors.red};
    color: #fff;

    &:hover {
      opacity: 0.9;
    }
  `,
  invisible: css`
    background: transparent;
    border: none;
    box-shadow: none;
    color: #606060;

    &:hover {
      background-color: ${theme.colors.borderGrey};
    }
  `
};

const sizes: { [key: string]: SerializedStyles } = {
  sm: css`
    padding: 6px 12px;
    font-size: 10px;
  `,
  md: css`
    font-size: 13px;
    padding: 10px 20px;
  `,
  lg: css`
    font-size: 20px;
    padding: 11px 25px;
  `
};
