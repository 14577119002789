import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { EventData, Swipeable } from 'react-swipeable';

type Props = {
  hackathonId: number;
  projectId: number;
  nextProjectId?: number;
  previousProjectId?: number;
};

export const ProjectNavigation: FC<Props> = ({
  hackathonId,
  projectId,
  previousProjectId,
  nextProjectId,
  children
}) => {
  const swipeContent = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [navigated, setNavigated] = useState(false);

  const onSwiping = useCallback((data: EventData) => {
    data.event.preventDefault();
    if (!swipeContent.current) return;

    swipeContent.current.style.transitionProperty = 'none';
    swipeContent.current.style.transform = `translateX(${data.deltaX * -1}px)`;
  }, []);

  const navigate = useCallback(
    (deltaX: number, toProjectId: number | undefined, exit: 'left' | 'right') => {
      if (!swipeContent.current) return;
      const canNavigate = !!toProjectId && Math.abs(deltaX) > swipeContent.current.clientWidth / 4;
      if (!canNavigate) {
        swipeContent.current.style.transitionProperty = swipeTransitionProperty;
        swipeContent.current.style.transform = '';
        return;
      }

      setNavigated(true);

      const translate = exit === 'left' ? '-100%' : '100%';
      swipeContent.current.style.transitionProperty = swipeTransitionProperty;
      swipeContent.current.style.transform = `translateX(${translate})`;

      setTimeout(() => history.push(`/hackathon/${hackathonId}/project/${toProjectId}`), 200);
    },
    [history, hackathonId, setNavigated]
  );

  const goToNextProject = useCallback((data: EventData) => navigate(data.deltaX, nextProjectId, 'left'), [
    navigate,
    nextProjectId
  ]);
  const goToPreviousProject = useCallback((data: EventData) => navigate(data.deltaX, previousProjectId, 'right'), [
    navigate,
    previousProjectId
  ]);

  return (
    <Swipeable onSwiping={onSwiping} onSwipedLeft={goToNextProject} onSwipedRight={goToPreviousProject}>
      <SwipeContent ref={swipeContent} key={projectId} navigated={navigated}>
        {children}
      </SwipeContent>
    </Swipeable>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const swipeTransitionProperty = 'opacity, transform';
const SwipeContent = styled.div<{ navigated: boolean }>`
  transition-duration: 0.2s;
  transition-property: ${swipeTransitionProperty};
  animation: ${fadeIn} 0.5s forwards;
  ${p =>
    p.navigated &&
    css`
      opacity: 0;
    `}
`;
