import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

export type TabProps = {
  title: ReactNode;
  tabKey: string;
};

export const Tab: FC<TabProps> = ({ children, tabKey, title, ...forwardedProps }) => {
  return (
    <Container {...forwardedProps} key={tabKey}>
      {children}
    </Container>
  );
};

const fadeIn = keyframes`
  from { opacity: 0 };
  to { opacity: 1 };
`;

const Container = styled.div`
  flex: 1;
  animation: ${fadeIn};
  animation-duration: 1s;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;
