import firebase from 'firebase/app';
import 'firebase/firestore';

export const firebaseConfig = {
  apiKey: 'AIzaSyC8PTPobfSAmchvSKMXXffZ4jVjTlcPDnQ',
  authDomain: 'appathon-240015.firebaseapp.com',
  databaseURL: 'https://appathon-240015.firebaseio.com',
  projectId: 'appathon-240015',
  storageBucket: 'appathon-240015.appspot.com',
  messagingSenderId: '72962737627',
  appId: '1:72962737627:web:00b7aa74ebe8b91972d3fe',
};

let isInitialized = false;

export function initializeFirebase() {
  if (!isInitialized) firebase.initializeApp(firebaseConfig);

  isInitialized = true;

  return firebase;
}
