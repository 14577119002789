import { useCallback, useEffect, useState } from 'react';
import { HackathonStage, Vote, VoteDto } from '../Api';
import { useApiClient } from './useApiClient';
import { useAuth0 } from '../Auth0Provider';

const votingStages = new Set([HackathonStage.Demo, HackathonStage.Vote]);
export const canVoteAtStage = (stage: HackathonStage) => votingStages.has(stage);

export const useVote = (hackathonId: number) => {
  const apiClient = useApiClient();
  const { isAuthenticated } = useAuth0();
  const [vote, setVote] = useState<Vote | null>(null);
  const [isRunning, setIsRunning] = useState(false);

  const submitVote = useCallback(
    (voteDto: VoteDto) => {
      async function submitVote() {
        if (!isAuthenticated) return;
        setIsRunning(true);

        const vote = await apiClient.vote.vote(hackathonId, voteDto);
        setVote(vote);
        setIsRunning(false);
      }
      submitVote();
    },
    [hackathonId, isAuthenticated, apiClient]
  );

  const retractVote = useCallback(() => {
    async function retractVote() {
      if (!isAuthenticated) return;
      setIsRunning(true);

      await apiClient.vote.retractVote(hackathonId);
      setVote(null);
      setIsRunning(false);
    }
    retractVote();
  }, [hackathonId, isAuthenticated, apiClient]);

  useEffect(() => {
    async function getVote() {
      if (!isAuthenticated) return;
      setIsRunning(true);

      const vote = await apiClient.vote.getVote(hackathonId);
      setVote(vote);
      setIsRunning(false);
    }
    getVote();
  }, [hackathonId, isAuthenticated, apiClient]);

  return { isRunning, vote, submitVote, retractVote };
};
