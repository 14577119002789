import styled from '@emotion/styled';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, FormEvent, useLayoutEffect, useRef, useState } from 'react';
import { Clapper } from '../../clapApp/Clapper';
import { useChat } from '../../hooks/useChat';
import { Profile } from '../shared/AppToolbar/Profile';
import { Button } from '../shared/Button';
import theme from '../shared/theme';
import { ChatMessage } from './ChatMessage';

type Props = {
  hackathonId: number;
};

export const Chat: FC<Props> = ({ hackathonId }) => {
  const { messageGroups, sendMessage, canSend } = useChat(hackathonId);
  const [newMessage, setNewMessage] = useState('');
  const messageContainer = useRef<HTMLDivElement>(null);

  const canSendMessage = canSend && newMessage && newMessage.match(/\S/);
  const sendNewMessage = (event?: FormEvent) => {
    if (!canSendMessage) return;
    event && event.preventDefault();
    sendMessage(newMessage);
    setNewMessage('');
  };

  useLayoutEffect(() => {
    if (!messageContainer.current) return;
    messageContainer.current.scrollTo(0, messageContainer.current.scrollHeight);
  });

  return (
    <Container>
      <Messages ref={messageContainer}>
        {messageGroups.map((group, i) => (
          <ChatMessage key={i} {...group} />
        ))}
      </Messages>
      <NewMessage onSubmit={sendNewMessage}>
        <ProfileContainer>
          <Profile />
        </ProfileContainer>
        <MessageInput
          disabled={!canSend}
          placeholder={canSend ? 'Say something' : 'Log in to chat'}
          value={newMessage}
          onChange={e => setNewMessage(e.currentTarget.value)}
          onKeyPress={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              sendNewMessage();
              e.preventDefault();
            }
          }}
          maxLength={400}
        />
        <SendButton category="primary" disabled={!canSendMessage} onClick={sendNewMessage}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </SendButton>
        <Clapper hackathonId={hackathonId} />
      </NewMessage>
    </Container>
  );
};

const Container = styled.div`
  ${theme.styles.materialPanel};
  background: #fff;
  flex: 1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Messages = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-bottom: 5px;
`;

const NewMessage = styled.form`
  display: flex;
`;

const MessageInput = styled.textarea`
  padding: 5px 10px;
  margin-right: 5px;
  flex: 1;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 40px;
`;

const SendButton = styled(Button)`
  padding: 5px 15px;
  font-size: 18px;
`;
