import styled from '@emotion/styled';
import React, { FC } from 'react';
import DismissableMessage from '../shared/DismissableMessage';

export const InfosecWarning: FC = () => (
  <DismissableMessage messageKey="infosec-warning">
    <Header>
      <span role="img" aria-label="secure">
        🔒
      </span>{' '}
      Official Infosec Notification{' '}
      <span role="img" aria-label="secure">
        🔒
      </span>
    </Header>
    <p>
      Remember: The project name and description are <strong>public</strong>, that is visible to the entire internet.
    </p>
    <p>
      Please be careful not to post anything here that could be linked to Trayport or would disclose any information
      about our products and services. Treat this like a social media post!
    </p>
    <p>And if you need a project idea, we could always use some more TLS inspection</p>
  </DismissableMessage>
);

const Header = styled.h2`
  margin-top: 0;
`;
