import styled from '@emotion/styled';
import React, { FC } from 'react';
import { animated, useTransition } from 'react-spring';
import { ResultDto } from '../../Api';
import theme from '../shared/theme';
import { PrizeIcon } from './PrizeIcon';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  results: ResultDto[];
  redactNames: boolean;
};

const itemHeight = 32;
const itemMargin = 10;
const itemPadding = 15;
const totalItemHeight = itemHeight + itemMargin + itemPadding * 2;

export const ResultsTable: FC<Props> = ({ results, redactNames }) => {
  const leaderboard = results
    .filter((r) => r.position <= 3)
    .map((result, index) => ({
      ...result,
      height: itemHeight,
      top: index * totalItemHeight,
    }));

  const listHeight = leaderboard.length * totalItemHeight;

  const transitions = useTransition(leaderboard, (l) => l.projectId, {
    from: { height: 0, opacity: 0 },
    leave: { height: 0, opacity: 0 },
    enter: ({ top, height }) => ({ top, height, opacity: 1 }),
    update: ({ top, height }) => ({ top, height }),
  });

  return (
    <List style={{ minHeight: listHeight }}>
      {transitions.map(({ item: result, props, key }) => (
        <ListItem key={key} style={props}>
          <PrizeIcon position={result.position} />
          <ProjectName>{redactNames ? <RedactedProjectName /> : result.projectName}</ProjectName>
          <Votes>{result.votes}</Votes>
        </ListItem>
      ))}
    </List>
  );
};

const RedactedProjectName: FC = () => (
  <Redacted>
    Mystery Project &nbsp;
    <FontAwesomeIcon icon={faUserSecret} />
  </Redacted>
);

const Redacted = styled.span`
  color: ${theme.colors.lightGrey};
`;

const Votes = styled.span`
  font-weight: bold;
`;

const List = styled.ul`
  list-style: none;
  margin: 20px 0;
  padding: 0;
  position: relative;
  flex: 1;
  overflow: hidden;
`;

const ListItem = styled(animated.li)`
  ${theme.styles.materialPanel}
  padding: ${itemPadding}px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${itemHeight}px;
  margin-bottom: ${itemMargin}px;
  position: absolute;
  left: 0;
  right: 0;
`;

const ProjectName = styled.div`
  padding-left: 15px;
  flex: 1;
`;
