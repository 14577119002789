import styled from '@emotion/styled';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Chat from '../Chat';
import AppToolbar from '../shared/AppToolbar';
import { Page } from '../shared/Layout';

type RouteParams = {
  hackathonId: string;
};

type Props = {
  hackathonId: number;
};

const ChatHomeComponent: FC<Props> = ({ hackathonId }) => (
  <Page>
    <AppToolbar hackathonId={hackathonId} />
    <PageContainer>
      <Chat hackathonId={hackathonId} />
    </PageContainer>
  </Page>
);

const PageContainer = styled.div`
  position: absolute;
  top: 50px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
`;

export const ChatHome: FC<RouteComponentProps<RouteParams>> = ({ match }) => (
  <ChatHomeComponent hackathonId={Number(match.params.hackathonId)} />
);
