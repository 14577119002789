import styled from '@emotion/styled';

export const screenMd = 786;

export const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 10px;
  flex: 1;
`;

export const PageTitle = styled.h1`
  margin-top: 0;
  text-align: center;
  font-size: 24px;

  @media (min-width: ${screenMd}px) {
    font-size: 32px;
  }
`;
