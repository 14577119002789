import styled from '@emotion/styled';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useState } from 'react';
import { animated, useSpring } from 'react-spring';

type Props = {
  initialIsExpanded: boolean;
  title: string;
  showToggleButton: boolean;
};

export const Expander: FC<Props> = ({ initialIsExpanded, showToggleButton, title, children }) => {
  const [isExpanded, setIsExpanded] = useState(initialIsExpanded);
  const toggleIsExpanded = useCallback(() => setIsExpanded(!isExpanded), [isExpanded, setIsExpanded]);
  const slideDown = useSpring({
    transform: `scaleY(${isExpanded ? 1 : 0})`,
    opacity: isExpanded ? 1 : 0
  });
  return (
    <>
      {showToggleButton && (
        <ExpanderButton onClick={toggleIsExpanded}>
          <ExpanderIcon icon={isExpanded ? faChevronDown : faChevronRight} />
          {title}
        </ExpanderButton>
      )}
      {isExpanded && <ExpanderContent style={slideDown}>{children}</ExpanderContent>}
    </>
  );
};

const ExpanderButton = styled.button`
  border: none;
  background: none;
  text-align: center;
  color: #999;
  cursor: pointer;
  outline: none;
  margin-right: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ExpanderIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const ExpanderContent = styled(animated.div)`
  transform-origin: top;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
