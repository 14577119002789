import { useState } from 'react';
import { ResultsDto } from '../Api';
import { environment } from '../environment';
import { useSignalrConnection } from '../useSignalrConnection';

export const useResults = (hackathonId?: number) => {
  const [results, setResults] = useState<ResultsDto | null>(null);

  const hubUrl = `${environment.apiUrl}/hubs/results?hackathonId=${hackathonId || ''}`;
  useSignalrConnection(hubUrl, {
    updateResults: setResults
  });

  return { results };
};
