import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Reactions } from '../../Api';
import theme from '../shared/theme';
import { ReactionEmoji } from '../shared/ReactionEmoji';

type Props = {
  toggleReaction(reaction: Reactions): void;
  reaction: Reactions;
  count: number;
  currentUserReacted?: boolean;
  isEnabled: boolean;
};

export const ReactionButton: FC<Props> = ({ reaction, count, currentUserReacted, isEnabled, toggleReaction }) => {
  return (
    <EmojiButton onClick={() => toggleReaction(reaction)} disabled={!isEnabled} checked={!!currentUserReacted}>
      <span>{count}</span>
      <ReactionEmoji reaction={reaction} />
    </EmojiButton>
  );
};

const EmojiButton = styled.button<{ checked: boolean }>`
  box-shadow: 0 2px 2px 0 rgba(50, 50, 50, 0.16), 0 0 2px 0 rgba(50, 50, 50, 0.12);
  border-radius: 20px;
  margin-top: 10px;
  font-size: 16px;
  background: transparent;
  transition-duration: 0.5s;
  transition-property: background-color, box-shadow, border-color;
  border: 2px solid transparent;
  outline: none;
  padding: 8px 16px;
  min-width: 80px;
  display: flex;
  cursor: pointer;

  &:disabled {
    opacity: 0.9;
  }

  & > * {
    flex: 1;
    text-align: center;
  }

  ${(p) =>
    p.checked &&
    css`
      background: #fff;
      border: 2px solid ${theme.colors.midBlue};
      box-shadow: 0 4px 5px 0 rgba(52, 122, 183, 0.16), 0 0 5px 0 rgba(52, 122, 183, 0.12);
    `}
`;
