import { useCallback, useEffect, useState } from 'react';
import { Shortlist } from '../components/HackathonHome/ShortlistProvider';
import { useApiClient } from './useApiClient';
import { useAuth0 } from '../Auth0Provider';

export const useShortlist = (hackathonId: number) => {
  const apiClient = useApiClient();
  const { isAuthenticated } = useAuth0();
  const [shortlist, setShortlist] = useState<Shortlist>({});
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    async function getShortlist() {
      if (!isAuthenticated) return;
      const projects = await apiClient.vote.getShortlist(hackathonId);
      const newShortlist: Shortlist = {};
      if (projects) projects.forEach(p => (newShortlist[p.projectId] = true));
      setShortlist(newShortlist);
    }
    getShortlist();
  }, [hackathonId, isAuthenticated, apiClient]);

  const toggle = useCallback(
    async (projectId: number) => {
      if (!isAuthenticated) return;
      if (isRunning) return;
      setIsRunning(true);
      if (shortlist[projectId]) {
        await apiClient.vote.deleteFromShortlist(hackathonId, projectId);
      } else {
        await apiClient.vote.addToShortlist(hackathonId, projectId);
      }
      const newShortlist = {
        ...shortlist,
        [projectId]: !shortlist[projectId]
      };
      setShortlist(newShortlist);
      setIsRunning(false);
    },
    [hackathonId, shortlist, setShortlist, isRunning, setIsRunning, isAuthenticated, apiClient]
  );

  return { shortlist, toggle, isRunning };
};
