import { useEffect, useReducer, useMemo, useCallback } from 'react';
import { useApiClient } from '../../../hooks/useApiClient';
import { initialState, reducer } from './store';
import { User } from '../../../Api';
import { useAuth0 } from '../../../Auth0Provider';

export const useTeam = (hackathonId: number, projectId: number) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useApiClient();
  const { user } = useAuth0();

  useEffect(() => {
    if (!user || !state.owner) return;

    dispatch({
      type: 'PROJECT_OWNERSHIP_CHANGED',
      isEditable: user.sub === state.owner.id
    });
  }, [state.owner, state.teamMembers, user]);

  useEffect(() => {
    async function load() {
      dispatch({ type: 'TEAM_LOADING', projectId });
      const teamMembers = await api.project.getProjectTeam(hackathonId, projectId);
      dispatch({ type: 'TEAM_LOADED', teamMembers });
    }
    load();
    // don't care about changes to api - auth has no effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hackathonId, projectId]);

  const addTeamMember = useCallback(
    async (user: User) => {
      if (state.isLoading) return;
      if (!!state.teamMembers.find(m => m.id === user.id)) return;

      await api.project.addTeamMember(hackathonId, projectId, user.id!);
      dispatch({
        type: 'TEAM_MEMBER_ADDED',
        user
      });
    },
    [api.project, hackathonId, projectId, state.isLoading, state.teamMembers]
  );

  const removeTeamMember = useCallback(
    async (user: User) => {
      if (state.isLoading) return;
      if (!state.teamMembers.find(m => m.id === user.id)) return;

      await api.project.removeTeamMember(hackathonId, projectId, user.id!);
      dispatch({
        type: 'TEAM_MEMBER_REMOVED',
        userId: user.id!
      });
    },
    [api.project, hackathonId, projectId, state.isLoading, state.teamMembers]
  );

  const result = useMemo(() => ({ ...state, addTeamMember, removeTeamMember }), [
    addTeamMember,
    removeTeamMember,
    state
  ]);
  return result;
};
