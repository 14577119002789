import styled from '@emotion/styled';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { Vote } from '../../Api';
import { Button } from '../shared/Button';
import theme from '../shared/theme';
import { ProjectVote } from './ProjectVote';
import { canVoteAtStage } from '../../hooks/useVote';
import { HackathonContext } from '../shared/HackathonContext';
import { environment } from '../../environment';

type Props = {
  vote: Vote;
  retractVote(): void;
};

export const VoteConfirmation: FC<Props> = ({ vote, retractVote }) => {
  const slideIn = useSpring(theme.springs.slideUp);
  const { hackathon } = useContext(HackathonContext);
  const canRetractVote = canVoteAtStage(hackathon!.currentStage);
  return (
    <ConfirmationContainer style={slideIn}>
      <ConfirmationPanel>
        <Title>Thanks for Voting!</Title>
        <Columns>
          <ProjectVote project={vote.teamProject!} />
          {!environment.singleVoteMode && <ProjectVote project={vote.individualProject!} />}
        </Columns>
        <Buttons>
          {canRetractVote && (
            <Button onClick={retractVote} size="sm">
              Reset Vote
            </Button>
          )}
          <ResultsLink to={`/hackathon/${vote.hackathonId}/result`}>
            Go to Results
            <ResultsIcon icon={faTrophy} color={theme.colors.orange} />
          </ResultsLink>
        </Buttons>
      </ConfirmationPanel>
    </ConfirmationContainer>
  );
};

const ConfirmationContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ConfirmationPanel = styled.div`
  ${theme.styles.materialPanel}
  background: #fff;
  align-self: stretch;
  margin: 0 20px;
  @media (min-width: 768px) {
    width: 600px;
    align-self: center;
  }
`;
const Title = styled.h3`
  text-align: center;
  border-bottom: 1px solid ${theme.colors.borderGrey};
  margin: 15px 0;
  padding: 0 0 15px 0;
`;
const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 480px) {
    flex-direction: row;
    & > * {
      flex: 1;
    }
  }
`;
const Buttons = styled.div`
  border-top: 1px solid ${theme.colors.borderGrey};
  margin: 15px 0 5px 0;
  padding: 15px 0 0 0;
  display: flex;
`;
const ResultsLink = styled(Link)`
  flex: 1;
  text-align: right;
  text-decoration: none;
  color: #202020;
  transition: color 0.2s;
  &:hover {
    color: orange;
  }
`;
const ResultsIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;
