import { useState, useEffect } from 'react';

export const useDecreasingVolume = (
  rateOfDecrease = 0.2,
  decreaseInterval = 100
) => {
  const [volume, setVolume] = useState(0);
  const isSilent = volume === 0;

  useEffect(() => {
    if (isSilent) return;

    const interval = setInterval(() => {
      setVolume((v) => {
        const diff = Math.max(0.01, v * rateOfDecrease);
        const newVolume = Math.max(0, v - diff);
        return newVolume;
      });
    }, decreaseInterval);

    return () => {
      clearInterval(interval);
    };
  }, [isSilent, decreaseInterval, rateOfDecrease]);

  return { volume, setVolume };
};
