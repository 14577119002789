import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { useHackathon } from '../../hooks/useHackathon';
import AppToolbar from '../shared/AppToolbar';
import DismissableMessage from '../shared/DismissableMessage';
import { HackathonContext } from '../shared/HackathonContext';
import { Page, PageBody, PageTitle } from '../shared/Layout';
import Require from '../shared/Require';
import { ProjectsOverview } from './ProjectsOverview';
import { ShortlistProvider } from './ShortlistProvider';
import { AddProjectMessage } from './AddProjectMessage';
import { LiveDemoMessage } from './LiveDemoMessage';
import ResultsSummary from '../ResultSummary';
import { GoToVotingMessage } from './GoToVotingMessage';

type RouteParams = { hackathonId?: string };

type Props = { hackathonId?: number };

const HackathonHome: FC<Props> = ({ hackathonId }) => {
  const { hackathon } = useHackathon(hackathonId);

  return (
    <>
      <Helmet>
        <title>{(hackathon && hackathon.name) || 'Home'}</title>
      </Helmet>
      <Page>
        <AppToolbar hackathonId={hackathonId} />
        <PageBody>
          <Require required={hackathon}>
            {() => (
              <HackathonContext.Provider value={{ hackathonId: hackathon!.hackathonId, hackathon }}>
                <ShortlistProvider hackathonId={hackathon!.hackathonId}>
                  <PageTitle>{hackathon!.name}</PageTitle>
                  <DismissableMessage messageKey="welcome">
                    Browse the hackathon projects below and shortlist them for later. When you're ready,{' '}
                    <Link to={`/hackathon/${hackathon!.hackathonId}/vote`}>vote!</Link>
                  </DismissableMessage>
                  <AddProjectMessage hackathonId={hackathon!.hackathonId} hackathonStage={hackathon!.currentStage} />
                  <GoToVotingMessage hackathonId={hackathon!.hackathonId} hackathonStage={hackathon!.currentStage} />
                  <LiveDemoMessage hackathonStage={hackathon!.currentStage} />
                  <ResultsSummary hackathon={hackathon!} />
                  <ProjectsOverview projects={hackathon!.projects!} />
                </ShortlistProvider>
              </HackathonContext.Provider>
            )}
          </Require>
        </PageBody>
      </Page>
    </>
  );
};

export const HackathonHomeRouteComponent: FC<RouteComponentProps<RouteParams>> = ({ match }) => (
  <HackathonHome hackathonId={Number(match.params.hackathonId)} />
);
