import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import theme from '../shared/theme';

type Props = {
  icon: IconProp;
  title: string;
};

export const StatsCell: FC<Props> = ({ children, icon, title }) => (
  <Container>
    <StatContainer>
      <Icon icon={icon} />
      <Stat>{children || '-'}</Stat>
      <Description>{title}</Description>
    </StatContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 5px;
  border-bottom: 1px solid ${theme.colors.borderGrey};
`;

const StatContainer = styled.div`
  height: 80px;
  display: grid;
  grid-template:
    'icon        stat'
    'description description' /
    50px 50px;
`;

const Icon = styled(FontAwesomeIcon)`
  grid-area: icon;
  font-size: 32px;
  text-align: center;
  align-self: center;
  color: #ddd;
`;

const Stat = styled.div`
  grid-area: stat;
  text-align: center;
  font-size: 40px;
`;

const Description = styled.div`
  grid-area: description;
  text-align: center;
  color: #999;
  font-size: 10px;
  text-transform: uppercase;
`;
