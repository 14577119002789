import React, { FC, useCallback } from 'react';
import { User } from '../../Api';
import { useApiClient } from '../../hooks/useApiClient';
import Autocomplete from './Autocomplete';

type Props = {
  placeholder?: string;
  onSelected(user: User): void;
};

const getDisplayName = (user: User) => user.displayName!;

export const UserSearchBox: FC<Props> = props => {
  const apiClient = useApiClient();
  const search = useCallback((searchTerm: string) => apiClient.search.findUsers(searchTerm), [apiClient.search]);

  return <Autocomplete {...props} getSuggestions={search} getDisplayName={getDisplayName} />;
};
