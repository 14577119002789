import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';
import { animated, useTransition } from 'react-spring';
import { ProjectCategory } from '../../Api';
import ProjectIcon from './ProjectIcon';
import theme from './theme';
import { environment } from '../../environment';

type Props = {
  teamContent: ReactNode;
  individualContent: ReactNode;
};

export const ProjectCategoryLists: FC<Props> = ({ teamContent, individualContent }) => {
  const listTransitions = useTransition([0, 1], null, theme.transitions.slideUp);
  const teamPrizeLabel = environment.singleVoteMode ? 'Projects' : 'Team Prize'
  return (
    <ProjectLists>
      <ProjectListContainer style={listTransitions[0].props}>
          <ListTitle>
            <ProjectIcon category={ProjectCategory.Team} />
            <TitleText>{teamPrizeLabel}</TitleText>
          </ListTitle>
        {teamContent}
      </ProjectListContainer>
      {!environment.singleVoteMode && (
        <ProjectListContainer style={listTransitions[1].props}>
          <ListTitle>
            <ProjectIcon category={ProjectCategory.Individual} />
            <TitleText>Individual Prize</TitleText>
          </ListTitle>
          {individualContent}
        </ProjectListContainer>
      )}
    </ProjectLists>
  );
};

const ProjectLists = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    & > * {
      flex: 1;
    }
  }
`;

const ProjectListContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 0 20px;
  overflow-x: hidden;
`;

const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const TitleText = styled.h2`
  flex: 1;
  margin: 0 10px;
  font-weight: normal;
`;
