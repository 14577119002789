import React, { FC, useCallback } from 'react';
import { useDemoSlot } from '../../hooks/useDemoSlot';
import { useApiClient } from '../../hooks/useApiClient';
import { Button } from '../shared/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

type Props = {
  hackathonId: number;
};

export const CurrentDemoSlotEditor: FC<Props> = ({ hackathonId }) => {
  const { demoSlot } = useDemoSlot();
  const apiClient = useApiClient();

  const nextDemoSlot = useCallback(async () => {
    if (!demoSlot) return;

    try {
      const currentSlot = demoSlot.slot === undefined || demoSlot.slot === null ? -1 : demoSlot.slot;
      await apiClient.hackathon.setCurrentDemoSlot(hackathonId, currentSlot + 1);
    } catch (err: any) {
      const is404 = err && err.status === 404;
      if (!is404) throw err;
    }
  }, [apiClient.hackathon, demoSlot, hackathonId]);

  const previousDemoSlot = useCallback(async () => {
    if (!demoSlot) return;

    if (!demoSlot.slot) await apiClient.hackathon.clearCurrentDemo(hackathonId);
    else await apiClient.hackathon.setCurrentDemoSlot(hackathonId, demoSlot.slot - 1);
  }, [demoSlot, apiClient, hackathonId]);

  if (!demoSlot) return null;

  const displaySlot = demoSlot.slot === undefined || demoSlot.slot === null ? '-' : demoSlot.slot + 1;
  const displayName = demoSlot.project ? demoSlot.project.name : '';

  const disabled = !demoSlot.isLive;

  return (
    <Container>
      <ButtonContainer>
        <Button size="sm" onClick={previousDemoSlot} disabled={disabled}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <CurrentSlot>{displaySlot}</CurrentSlot>
        <Button size="sm" onClick={nextDemoSlot} category="primary" disabled={disabled}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </ButtonContainer>
      <Title>
        Current: <DemoName>{displayName}</DemoName>
      </Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 39px; /*to align with table of projects*/
`;

const Title = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 100;
  margin-left: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DemoName = styled.span`
  font-weight: normal;
`;

const ButtonContainer = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;

const CurrentSlot = styled.div`
  min-width: 30px;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;
