import styled from '@emotion/styled';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback } from 'react';
import { Button } from '../Button';
import { InlineInput } from '../InlineInput';

type Props = {
  imageUrl: string;
  onChange(imageUrl: string): void;
};

export const ManualUrlEntry: FC<Props> = ({ imageUrl, onChange }) => {
  const clearUrl = useCallback(() => onChange(''), [onChange]);

  return (
    <>
      <Description>Enter an image URL below to use as your hero image</Description>
      <InputContainer>
        <UrlInput multiline value={imageUrl} onChange={onChange} placeholder="http://image/url/from/google.png" />
        <ClearButton size="sm" category="transparent" onClick={clearUrl}>
          <FontAwesomeIcon icon={faTrash} />
        </ClearButton>
      </InputContainer>
    </>
  );
};

const UrlInput = styled(InlineInput)`
  flex: 1;
  font-size: 16px;
  margin-right: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ClearButton = styled(Button)`
  align-self: flex-start;
`;

const Description = styled.p`
  font-size: 11px;
`;
