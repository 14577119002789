import React, { FC, useEffect, useRef } from 'react';

type Props = {
  type: 'crowd' | 'single';
  volume: number;
};

export const Applause: FC<Props> = ({ type, volume }) => {
  const audio = useRef<HTMLAudioElement>(null);
  const safeVolume = Math.min(1, Math.max(0, volume));
  useEffect(() => {
    if (!audio.current) return;
    audio.current.volume = safeVolume;
    if (safeVolume === 0) {
      audio.current.pause();
      audio.current.currentTime = 0;
    } else {
      audio.current.play();
    }
  }, [safeVolume]);

  return <audio ref={audio} preload="auto" loop src={`https://tapclap.app/audio/${type}.mp3`} />;
};
