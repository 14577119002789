import styled from '@emotion/styled';
import React, { FC, useCallback, MouseEvent } from 'react';
import { useAuth0 } from '../../Auth0Provider';

interface Props {
  onClick(): void;
}

export const AuthenticatedButton: FC<Props> = ({ children, onClick, ...forwardedProps }) => {
  const { isAuthenticated } = useAuth0();

  const onClickPreventForward = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onClick();
    },
    [onClick]
  );

  if (isAuthenticated === true)
    return (
      <TransparentButton onClick={onClickPreventForward} {...forwardedProps}>
        {children}
      </TransparentButton>
    );
  // else if (isAuthenticated === false) return <LoginLink>{children}</LoginLink>;

  return null;
};

const TransparentButton = styled.button`
  border: none;
  background: none;
  outline: none;
  display: flex;
`;
