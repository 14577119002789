import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import theme from '../shared/theme';

type Props = {
  isRunning: boolean;
  hasCompleted?: boolean;
  successMessage?: string;
};

export const AsyncStatusIcon: FC<Props> = ({ isRunning, hasCompleted, successMessage, ...forwardedProps }) => (
  <Container {...forwardedProps}>
    {isRunning && <FontAwesomeIcon color={theme.colors.orange} spin icon={faSpinner} />}
    {hasCompleted && !isRunning && (
      <Completed>
        <Message>{successMessage}</Message>
        <FontAwesomeIcon color={theme.colors.green} icon={faCheck} />
      </Completed>
    )}
  </Container>
);

const fadeOut = keyframes`
  from { opacity: 0 };
  10% { opacity: 1 };
  80% { opacity: 1 };
  to { opacity: 0};
  }
`;

const Container = styled.div`
  min-width: 16px;
`;

const Completed = styled.div`
  animation: ${fadeOut} 5s forwards;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme.colors.lighterGreen};
  border-radius: 3px;
  padding: 3px 6px;
`;

const Message = styled.span`
  color: ${theme.colors.green};
  display: inline-block;
  margin-right: 0.5em;
  font-size: 12px;
`;
