import styled from '@emotion/styled';
import { faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { animated, useSpring } from 'react-spring';
import theme from '../shared/theme';
import DismissableMessage from '../shared/DismissableMessage';

export const NoResultsPlaceholder: FC = () => {
  const fadeIn = useSpring(theme.springs.fadeIn);
  return (
    <Container style={fadeIn}>
      <Icon icon={faVoteYea} color={theme.colors.borderGrey} size="7x" />
      <DismissableMessage messageKey="noResults">Results will be displayed as votes come in...</DismissableMessage>
    </Container>
  );
};

const Container = styled(animated.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: 20px 0 50px 0;
`;
