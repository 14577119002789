import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ProjectData, ProjectCategory } from '../../Api';
import { InlineInput } from '../shared/InlineInput';
import theme from '../shared/theme';
import { AsyncStatusIcon } from './AsyncStatusIcon';
import { HeroImagePickerModal } from './HeroImagePickerModal';
import { ProjectCategoryButton } from './ProjectCategoryButton';
import { environment } from '../../environment';

type Props = {
  project: ProjectData;
  isEditable: boolean;
  isUpdating: boolean;
  hasUpdated: boolean;
  update(updatedData: Partial<ProjectData>): void;
};

export const ProjectEditor: FC<Props> = ({ project, update, isEditable, isUpdating, hasUpdated }) => {
  const canEditCategory = isEditable && !environment.singleVoteMode;
  const projectCategory = environment.singleVoteMode ? ProjectCategory.Team : project.category;

  return (
    <ProjectContainer>
      <IconContainer>
        <ProjectCategoryButton
          disabled={!canEditCategory}
          onChange={(category) => update({ category })}
          defaultValue={projectCategory}
        />
      </IconContainer>
      <ProjectContent>
        <Title
          placeholder="Project Name"
          required
          disabled={!isEditable}
          defaultValue={project.name}
          onChange={(name) => update({ name })}
        />
        <SaveStatus isRunning={isUpdating} hasCompleted={hasUpdated} successMessage="Saved" />
        <Columns>
          <LeftColumn>
            <Description
              required
              multiline
              placeholder="Short description of your project"
              disabled={!isEditable}
              defaultValue={project.shortDescription}
              onChange={(shortDescription) => update({ shortDescription })}
            />
          </LeftColumn>
          <HeroImageContainer>
            <HeroImagePickerModal
              disabled={!isEditable}
              heroImageUrl={project.heroImageUrl}
              onChange={(heroImageUrl) => update({ heroImageUrl })}
            />
            <TeamName
              required
              placeholder="Team Name"
              disabled={!isEditable}
              defaultValue={project.teamName}
              onChange={(teamName) => update({ teamName })}
            ></TeamName>
          </HeroImageContainer>
        </Columns>
      </ProjectContent>
    </ProjectContainer>
  );
};

const ProjectContainer = styled.div`
  padding: 20px;
  position: relative;
  height: 200px;
  display: flex;
  flex-direction: column;
`;

const SaveStatus = styled(AsyncStatusIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;

const ProjectContent = styled.div`
  ${theme.styles.materialPanel}
  top: 25px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  position: absolute;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  top: 10px;
  left: 20px;
  position: absolute;
  z-index: 1;
`;

const HeroImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(InlineInput)`
  margin-left: 65px;
  padding: 3px;
  display: block;
  font-size: 14px;
  margin-top: 0;
  color: black;
`;

const TeamName = styled(InlineInput)`
  font-weight: normal;
  font-size: 12px;
  color: #404040;
  margin: 10px 0 0 0;
  text-align: center;
  width: 100%;
`;

const Columns = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Description = styled(InlineInput)`
  color: #202020;
  font-size: 16px;
  font-weight: lighter;
  flex: 1;
  margin-bottom: 0;
  margin-top: 16px;
`;

const LeftColumn = styled.div`
  width: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`;
