import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Project, ProjectCategory } from '../../Api';
import { ProjectHeroImage } from '../HackathonHome/ProjectHeroImage';

type Props = { project: Project };

const titleText = {
  [ProjectCategory.Team]: 'Team Prize',
  [ProjectCategory.Individual]: 'Individual Prize',
};

export const ProjectVote: FC<Props> = ({ project }) => (
  <VoteContainer>
    <CategoryTitle>
      <TitleText>{titleText[project.category]}</TitleText>
    </CategoryTitle>
    <ProjectHeroImage project={project} />
    <Title>{project.name}</Title>
  </VoteContainer>
);

const VoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CategoryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
const TitleText = styled.h5`
  flex: 1;
  margin: 10px;
  font-weight: normal;
  color: #909090;
`;

const Title = styled.h4`
  margin: 10px;
  font-weight: normal;
  text-align: center;
`;
