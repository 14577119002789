import styled from '@emotion/styled';
import { faInfoCircle, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useState, ReactNode } from 'react';
import { Button } from '../Button';
import theme from '../theme';
import { animated, useSpring } from 'react-spring';

type Props = { messageKey: string; icon?: ReactNode };

export const DismissableMessage: FC<Props> = ({ messageKey, icon, children }) => {
  const storageKey = `MessageDismissed:${messageKey}`;
  const [isDismissed, setIsDismissed] = useState(!!localStorage.getItem(storageKey));

  const fadeIn = useSpring(theme.springs.fadeIn);

  const dismiss = useCallback(() => {
    localStorage.setItem(storageKey, 'true');
    setIsDismissed(true);
  }, [storageKey, setIsDismissed]);

  if (isDismissed) return null;

  return (
    <Container style={fadeIn}>
      {icon || <FontAwesomeIcon icon={faInfoCircle} size="2x" color={theme.colors.blue} />}
      <Content>{children}</Content>
      <DismissButtonContainer>
        <Button category="primary" size="sm" onClick={dismiss}>
          <ButtonIcon icon={faThumbsUp} />
          Got it, thanks
        </Button>
      </DismissButtonContainer>
    </Container>
  );
};

const Container = styled(animated.div)`
  ${theme.styles.materialPanel}
  background: ${theme.colors.lightBlue};
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  margin: 0 10px 20px 10px;
  font-size: 14px;
`;

const Content = styled.div`
  grid-column: 2 / 4;
  margin-left: 10px;
  margin-bottom: 15px;
  align-self: center;
  font-size: 12px;
`;

const DismissButtonContainer = styled.div`
  grid-column: 3;
  align-self: end;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
