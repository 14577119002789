import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Page, PageBody } from './shared/Layout';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import Loading from './shared/Loading';
import { useAuth0 } from '../Auth0Provider';
import { Button } from './shared/Button';

export const AuthRedirect: FC = () => {
  const { error, logout } = useAuth0();
  return (
    <>
      <Helmet>
        <title>Redirecting...</title>
      </Helmet>
      <Page>
        <PageBody>
          <SpinnerContainer>{error ? <AuthError logout={() => logout()} /> : <Loading />}</SpinnerContainer>
        </PageBody>
      </Page>
    </>
  );
};

const AuthError: FC<{ logout(): void }> = (props) => (
  <>
    <h2>Oh no, that didn't work!</h2>
    <p>
      Maybe another account will work better? Remember that only <strong>@trayport.com</strong> accounts are supported
    </p>
    <Button onClick={props.logout}>Log out</Button>
  </>
);

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: ${fadeIn} 2s forwards;
  animation-delay: 2s;
`;
