import styled from '@emotion/styled';
import { faCheck, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Project } from '../../Api';
import theme from '../shared/theme';
import { ProjectHeroImage } from '../HackathonHome/ProjectHeroImage';

type Props = {
  projects: Project[];
  isShortlisted: boolean;
  selectedProject?: number;
  onSelectionChange(projectId: number): void;
};

export const ProjectButtonsList: FC<Props> = ({ projects, isShortlisted, selectedProject, onSelectionChange }) => (
  <>
    {projects.map((project) => (
      <ProjectButton
        key={project.projectId}
        selected={selectedProject === project.projectId}
        onClick={() => onSelectionChange(project.projectId)}
      >
        <HeroImage>
          <ProjectHeroImage project={project} size="sm" />
        </HeroImage>
        {isShortlisted && <StarIcon icon={faStar} color={theme.colors.orange} size="1x" />}
        <ProjectName>{project.name}</ProjectName>
        <CheckIcon icon={faCheck} selected={selectedProject === project.projectId} />
      </ProjectButton>
    ))}
  </>
);

const ProjectButton = styled.button<{ selected: boolean }>`
  ${theme.styles.materialPanel}
  margin: 3px;
  margin-bottom: 10px;
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding: 8px 15px;
  min-height: 45px;
  transition-duration: 0.3s;
  transition-property: background-color, color;
  background-color: ${(props) => (props.selected ? theme.colors.blue : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#202020')};
`;

const StarIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const HeroImage = styled.div`
  height: 30px;
  width: 30px;
  position: relative;
`;

const ProjectName = styled.span`
  flex: 1;
  text-align: left;
  margin-left: 10px;
`;

const CheckIcon = styled(FontAwesomeIcon)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? 'inherit' : 'transparent')};
`;
