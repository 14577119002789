import createAuth0Client, {
  Auth0Client,
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions,
} from '@auth0/auth0-spa-js';
import React, { createContext, FC, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { randomString } from './randomString';

type RedirectState = { redirectUri?: string };
const REDIRECT_STATE_KEY = 'redirect';
const DEFAULT_REDIRECT_CALLBACK = (nonce?: string) => {
  const storedState = localStorage.getItem(REDIRECT_STATE_KEY);
  localStorage.removeItem(REDIRECT_STATE_KEY);

  if (nonce && storedState) {
    const redirectState = JSON.parse(storedState)[nonce] as RedirectState;
    if (redirectState && redirectState.redirectUri) {
      window.location.href = redirectState.redirectUri;
      return;
    }
  }

  window.history.replaceState({}, document.title, window.location.pathname);
};

export interface User {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  'https://appathon.online/roles': string[];
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

export type GetTokenSilently = (options?: GetTokenSilentlyOptions) => Promise<any>;
export interface Auth0State {
  isAuthenticated: boolean;
  isAdmin: boolean;
  user: User | undefined;
  loading: boolean;
  error: boolean;
  handleRedirectCallback(): Promise<void>;
  getIdTokenClaims(options?: getIdTokenClaimsOptions): Promise<IdToken>;
  loginWithRedirect(options: RedirectState): Promise<void>;
  login(): Promise<void>;
  getTokenSilently: GetTokenSilently;
  logout(options?: LogoutOptions): void;
}

type Props = {
  domain: string;
  audience: string;
  client_id: string;
  redirect_uri: string;
  onRedirectCallback?: typeof DEFAULT_REDIRECT_CALLBACK;
};

export const Auth0Context = createContext<Auth0State>({} as Auth0State);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider: FC<Props> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initProps
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...initProps,
        useRefreshTokens: true,
      });
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser<User>();
        user && setUser(user);
      } else {
        const isError = window.location.pathname === '/signin' && window.location.search.includes('error=');
        if (isError) {
          setError(true);
        }
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithRedirect = useCallback(
    async (state?: RedirectState) => {
      if (!auth0Client) return;

      state = state || {
        redirectUri: window.location.toString(),
      };

      const nonce = randomString(10);
      localStorage.setItem(
        REDIRECT_STATE_KEY,
        JSON.stringify({
          [nonce]: state,
        })
      );

      await auth0Client.loginWithRedirect({
        redirect_uri: new URL('/signin', window.location.origin).href,
        appState: nonce,
        prompt: 'select_account',
      });
    },
    [auth0Client]
  );

  const login = useCallback(() => loginWithRedirect(), [loginWithRedirect]);

  const handleRedirectCallback = useCallback(async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser<User>();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  }, [auth0Client]);

  const isAdmin = useMemo(() => {
    const roles: string[] = user ? user!['https://appathon.online/roles'] : [];
    if (!roles) return false;
    return roles.indexOf('admin') !== -1;
  }, [user]);

  const getTokenSilently = useMemo(
    () =>
      (...p: any) =>
        auth0Client && auth0Client.getTokenSilently(...p),
    [auth0Client]
  );

  const value = useMemo(
    () => ({
      isAuthenticated,
      isAdmin,
      user,
      loading,
      handleRedirectCallback,
      getIdTokenClaims: (...p: any) => auth0Client && auth0Client.getIdTokenClaims(...p),
      loginWithRedirect,
      login,
      getTokenSilently,
      logout: (...p: any) => auth0Client && auth0Client.logout(...p),
      error,
    }),
    [
      isAuthenticated,
      isAdmin,
      user,
      loading,
      handleRedirectCallback,
      auth0Client,
      loginWithRedirect,
      login,
      getTokenSilently,
      error,
    ]
  );

  return <Auth0Context.Provider value={value as Auth0State}>{children}</Auth0Context.Provider>;
};
