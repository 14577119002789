import { TabProps } from './Tab';
import styled from '@emotion/styled';
import css from '@emotion/css';
import React, { FC } from 'react';
import theme from '../theme';

type Props = {
  tabs: TabProps[];
  selectedTab: string;
  select(key: string): void;
};

export const TabList: FC<Props> = ({ tabs, selectedTab, select }) => (
  <Container>
    {tabs.map(tab => (
      <TabButton key={tab.tabKey} selected={tab.tabKey === selectedTab} onClick={() => select(tab.tabKey)}>
        {tab.title}
      </TabButton>
    ))}
  </Container>
);

const Container = styled.nav`
  display: flex;
  flex-direction: row;
`;

const TabButton = styled.button<{ selected: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-bottom: 2px solid ${theme.colors.borderGrey};
  background: transparent;
  color: ${theme.colors.lightGrey};
  padding: 10px 20px;
  outline: none;
  transition-property: color, background-color, border-color;
  transition-duration: 0.5s;
  cursor: pointer;

  ${p =>
    p.selected &&
    css`
      color: ${theme.colors.blue};
      background: ${theme.colors.lightBlue};
      border-color: ${theme.colors.blue};
      cursor: default;
    `}
`;
