import React, { FC } from 'react';
import { useShortlist } from '../../hooks/useShortlist';

export type Shortlist = {
  [projectId: number]: boolean | undefined;
};
type ShortlistContextData = {
  shortlist: Shortlist;
  toggle(projectId: number): void;
  isRunning: boolean;
};

export const ShortlistContext = React.createContext<ShortlistContextData>({
  shortlist: {},
  toggle() {},
  isRunning: false
});

export const ShortlistProvider: FC<{ hackathonId: number }> = ({ hackathonId, children }) => {
  const data = useShortlist(hackathonId);

  return <ShortlistContext.Provider value={data}>{children}</ShortlistContext.Provider>;
};
