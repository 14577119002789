import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import { Applause } from './Applause';
import { ClapButton } from './ClapButton';
import { useClapStore } from './useClapStore';
import { useDecreasingVolume } from './useDecreasingVolume';

type Props = {
  hackathonId: number;
};

export const Clapper: FC<Props> = ({ hackathonId }) => {
  const { volume, setVolume } = useDecreasingVolume(0.2, 100);
  const roomName = `hackathon-${hackathonId}`;

  const onClap = useCallback(() => setVolume((v) => Math.min(1.5, v + 0.1)), [setVolume]);
  const { sendClap } = useClapStore(roomName, onClap);
  const buttonScale = volume * 3 + 1;

  return (
    <Container>
      <ClapButton onClick={sendClap} scale={buttonScale} />
      <Applause type="single" volume={volume} />
      <Applause type="crowd" volume={volume - 0.2} />
    </Container>
  );
};

const Container = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  z-index: 100;
  display: flex;
  & > * {
    flex: 1;
  }
`;
