import React, { useState, useCallback } from 'react';
import Autosuggest, { SuggestionsFetchRequestedParams, SuggestionSelectedEventData } from 'react-autosuggest';
import './react-autosuggest.css';

type Props<T> = {
  getDisplayName(suggestion: T): string;
  getSuggestions(searchTerm: string): T[] | Promise<T[]>;
  onSelected(item: T): void;
  placeholder?: string;
};

export function Autocomplete<T>({ getSuggestions, getDisplayName, onSelected, placeholder = 'Search' }: Props<T>) {
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const onSuggestionsFetchRequested = useCallback(
    async (params: SuggestionsFetchRequestedParams) => {
      const results = await getSuggestions(params.value);
      setSuggestions(results);
    },
    [getSuggestions]
  );
  const onSuggestionsClearRequested = useCallback(() => setSuggestions([]), []);
  const onSuggestionSelected = useCallback(
    (_: any, params: SuggestionSelectedEventData<T>) => {
      onSelected(params.suggestion);
      setSearchTerm('');
    },
    [onSelected]
  );

  const inputProps = {
    value: searchTerm,
    placeholder,
    onChange(event: React.FormEvent<HTMLInputElement>) {
      setSearchTerm(event.currentTarget.value);
    }
  };

  return (
    <Autosuggest<T, string>
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      multiSection={false}
      getSuggestionValue={getDisplayName}
      alwaysRenderSuggestions={false}
      renderSuggestion={u => <span>{getDisplayName(u)}</span>}
      inputProps={inputProps}
    />
  );
}
