import React, { FC, useCallback } from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router';
import { useAuth0 } from './Auth0Provider';
import Loading from './components/shared/Loading';

export const PrivateRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  const render = useCallback(
    (props: RouteComponentProps) => {
      if (loading) return null;

      if (!isAuthenticated) {
        loginWithRedirect({ redirectUri: props.location.pathname });
        return <Loading />;
      }

      const Component = component as any;
      return <Component {...props} />;
    },
    [component, loading, isAuthenticated, loginWithRedirect]
  );

  return <Route {...rest} render={render} />;
};
