import styled from '@emotion/styled';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Button } from '../Button';
import Loading from '../Loading';
import theme from '../theme';
import { UserSearchBox } from '../UserSearchBox';
import { TeamMember } from './TeamMember';
import { useTeam } from './useTeam';

type Props = {
  hackathonId: number;
  projectId: number;
  isEditable?: boolean;
};

export const Team: FC<Props> = ({ hackathonId, projectId, isEditable = true }) => {
  const {
    isLoading,
    owner,
    teamMembers,
    addTeamMember,
    removeTeamMember,
    pendingTeamMember,
    isEditable: isTeamEditable
  } = useTeam(hackathonId, projectId);

  if (isLoading || !owner) return <Loading size="sm" />;

  isEditable = isEditable && isTeamEditable;

  return (
    <Container isEditable={isEditable}>
      <Row>
        <TeamMember key={owner.id} user={owner} />
      </Row>
      {teamMembers.map(user => (
        <Row key={user.id}>
          <TeamMember user={user} />
          {isEditable && (
            <Button category="danger" size="sm" onClick={() => removeTeamMember(user)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </Row>
      ))}
      {pendingTeamMember && (
        <PendingContainer>
          <TeamMember key={pendingTeamMember.id} user={pendingTeamMember} />
        </PendingContainer>
      )}
      {isEditable && (
        <Row>
          <AddIcon icon={faPlusCircle} color={theme.colors.green} />
          <UserSearchBox onSelected={addTeamMember} placeholder="Add team member" />
        </Row>
      )}
    </Container>
  );
};

const Container = styled.div<{ isEditable: boolean }>`
  margin: 10px 10px 10px 0;
  ${p =>
    p.isEditable &&
    `
    /* 200px to allow dropdown to appear on mobile displays */
    margin-bottom: 200px;
  `}
`;

const PendingContainer = styled.div`
  opacity: 0.3;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 500px) {
    width: 300px;
  }
`;

const AddIcon = styled(FontAwesomeIcon)`
  margin: 10px;
  font-size: 32px;
`;
