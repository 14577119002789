import React, { FC } from 'react';
import { User } from '../../../Api';
import styled from '@emotion/styled';
import { ProfileImage } from '../ProfileImage';

type Props = {
  user?: User;
};

export const TeamMember: FC<Props> = ({ user }) => {
  if (!user || !user.displayName) return null;

  return (
    <Container>
      <ProfileImage user={user} />
      <Name>{user.displayName}</Name>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  font-size: 14px;
  flex: 1;
`;

const Name = styled.span`
  margin: 5px 10px;
`;
