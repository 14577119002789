import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { useHackathon } from '../../hooks/useHackathon';
import { useShortlist } from '../../hooks/useShortlist';
import { useVote } from '../../hooks/useVote';
import AppToolbar from '../shared/AppToolbar';
import { Page, PageBody } from '../shared/Layout';
import Require from '../shared/Require';
import { ProjectSelection } from './ProjectSelection';
import { VoteConfirmation } from './VoteConfirmation';
import { HackathonContext } from '../shared/HackathonContext';
import { useResults } from '../../hooks/useResults';

type RouteParams = { hackathonId: string };

type Props = { hackathonId: number };

const VotingHomeComponent: FC<Props> = ({ hackathonId }) => {
  const { hackathon } = useHackathon(hackathonId);
  const { shortlist, isRunning: isShortlistLoading } = useShortlist(hackathonId);
  const { vote, retractVote, submitVote, isRunning: isVoteRunning } = useVote(hackathonId);
  const { results } = useResults(hackathonId);
  const isReady = !!hackathon && !isShortlistLoading && !isVoteRunning && !!results;

  return (
    <HackathonContext.Provider value={{ hackathon, hackathonId }}>
      <Helmet>
        <title>{(hackathon && `${hackathon.name} Vote`) || 'Vote'}</title>
      </Helmet>
      <Page>
        <AppToolbar hackathonId={hackathonId} />
        <PageBody>
          <Require required={isReady}>
            {() =>
              !!vote ? (
                <VoteConfirmation vote={vote} retractVote={retractVote} />
              ) : (
                <ProjectSelection
                  projects={hackathon!.projects!}
                  shortlist={shortlist!}
                  results={results!}
                  submitVote={submitVote}
                />
              )
            }
          </Require>
        </PageBody>
      </Page>
    </HackathonContext.Provider>
  );
};

export const VotingHome: FC<RouteComponentProps<RouteParams>> = ({ match }) => (
  <VotingHomeComponent hackathonId={Number(match.params.hackathonId)} />
);
