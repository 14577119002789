import { User } from '../../../Api';

export interface TeamLoading {
  type: 'TEAM_LOADING';
  projectId: number;
}

interface TeamLoaded {
  type: 'TEAM_LOADED';
  teamMembers: User[];
}

interface AddTeamMemberRequested {
  type: 'ADD_TEAM_MEMBER_REQUESTED';
  user: User;
}

interface TeamMemberAdded {
  type: 'TEAM_MEMBER_ADDED';
  user: User;
}

interface TeamMemberRemoved {
  type: 'TEAM_MEMBER_REMOVED';
  userId: string;
}

interface OwnershipChanged {
  type: 'PROJECT_OWNERSHIP_CHANGED';
  isEditable: boolean;
}

type Actions =
  | TeamLoading
  | TeamLoaded
  | AddTeamMemberRequested
  | TeamMemberAdded
  | TeamMemberRemoved
  | OwnershipChanged;

interface TeamState {
  owner?: User;
  teamMembers: User[];
  isLoading: boolean;
  isEditable: boolean;
  pendingTeamMember?: User;
}

export const initialState: TeamState = {
  teamMembers: [],
  isLoading: false,
  isEditable: false
};

export const reducer = (state = initialState, action: Actions): TeamState => {
  switch (action.type) {
    case 'TEAM_LOADING':
      return {
        ...state,
        teamMembers: [],
        isLoading: true
      };
    case 'TEAM_LOADED':
      const [owner, ...teamMembers] = action.teamMembers;
      return {
        ...state,
        owner,
        teamMembers,
        isLoading: false,
        pendingTeamMember: undefined
      };
    case 'TEAM_MEMBER_ADDED':
      return {
        ...state,
        teamMembers: state.teamMembers.concat(action.user),
        pendingTeamMember: undefined
      };
    case 'ADD_TEAM_MEMBER_REQUESTED': {
      return {
        ...state,
        pendingTeamMember: action.user
      };
    }
    case 'PROJECT_OWNERSHIP_CHANGED': {
      return {
        ...state,
        isEditable: action.isEditable
      };
    }
    case 'TEAM_MEMBER_REMOVED': {
      return {
        ...state,
        teamMembers: state.teamMembers.filter(u => u.id !== action.userId)
      };
    }
    default:
      return state;
  }
};
