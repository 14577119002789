import styled from '@emotion/styled';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Project } from '../../Api';
import { useApiClient } from '../../hooks/useApiClient';
import { Button } from '../shared/Button';
import Require from '../shared/Require';
import theme from '../shared/theme';
import { DemoSlotRow } from './DemoSlotRow';
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';
import { CurrentDemoSlotEditor } from './CurrentDemoSlotEditor';

type Props = {
  hackathonId: number;
};

export const DemoSlotEditor: FC<Props> = ({ hackathonId }) => {
  const apiClient = useApiClient();
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    async function loadProjects() {
      const projects = await apiClient.project.getAll(hackathonId);
      if (projects) {
        projects.sort(sortByDemoSlot);
        setProjects(projects);
      }
    }
    loadProjects();
  }, [hackathonId, apiClient, setProjects]);

  const saveDemoProjects = useCallback(
    async (projectIds: number[]) => {
      const updatedProjects = await apiClient.project.setDemoSlots(hackathonId, projectIds);
      if (updatedProjects) {
        updatedProjects.sort(sortByDemoSlot);
        setProjects(updatedProjects);
      }
    },
    [apiClient, hackathonId, setProjects]
  );

  const cancelDemo = useCallback(
    projectId =>
      saveDemoProjects(
        projects
          .filter(project => project.demoSlot !== undefined)
          .map(project => project.projectId)
          .filter(id => id !== projectId)
      ),
    [projects, saveDemoProjects]
  );

  const addDemo = useCallback(
    projectId =>
      saveDemoProjects(
        projects
          .filter(project => project.demoSlot !== undefined)
          .map(project => project.projectId)
          .concat(projectId)
      ),
    [projects, saveDemoProjects]
  );

  const clearAll = useCallback(() => saveDemoProjects([]), [saveDemoProjects]);
  const disableClearAll = !projects.filter(p => p.demoSlot !== undefined).length;

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      const newProjects = [...projects];
      newProjects.splice(newIndex, 0, newProjects.splice(oldIndex, 1)[0]);

      saveDemoProjects(newProjects.filter(p => p.demoSlot !== undefined).map(p => p.projectId));
      setProjects(newProjects);
    },
    [projects, saveDemoProjects]
  );

  return (
    <Require required={projects.length}>
      {() => (
        <>
          <Toolbar>
            <Header>Demo Schedule</Header>
            <Button category="danger" size="sm" onClick={clearAll} disabled={disableClearAll}>
              Reset All
            </Button>
          </Toolbar>
          <CurrentDemoSlotEditor hackathonId={hackathonId} />
          <SortableTable onSortEnd={onSortEnd} useDragHandle lockAxis="y" helperClass="sorting">
            <tbody>
              {projects!.map((p, index) => (
                <SortableRow index={index} key={p.projectId} project={p} addDemo={addDemo} cancelDemo={cancelDemo} />
              ))}
            </tbody>
          </SortableTable>
        </>
      )}
    </Require>
  );
};

const sortByDemoSlot = (a: Project, b: Project) => {
  if (a.demoSlot === null) return b.demoSlot === null ? 0 : 1;

  if (b.demoSlot === null) return -1;

  return a.demoSlot! - b.demoSlot!;
};

const Table = styled.table`
  ${theme.styles.materialPanel}
  font-size: 12px;
  border-spacing: 0;
  table-layout: fixed;

  & tr:nth-of-type(even) {
    background: ${theme.colors.borderGrey};
  }

  & td {
    padding: 3px 8px;
  }
`;

const SortableTable = SortableContainer(Table);
const SortableRow = SortableElement(DemoSlotRow);

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-right: 10px;
`;

const Header = styled.h3`
  margin: 0;
  flex: 1;
  font-weight: 100;
`;
