import styled from '@emotion/styled';
import React, { FC, useEffect, useState } from 'react';
import { Hackathon, HackathonSummaryDto, HackathonStage } from '../../Api';
import { useApiClient } from '../../hooks/useApiClient';
import { useResults } from '../../hooks/useResults';
import theme from '../shared/theme';
import { Winners } from './Winners';
import { HackathonSummaryStats } from './HackathonSummaryStats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

type Props = {
  hackathon: Hackathon;
};

export const ResultsSummary: FC<Props> = ({ hackathon }) => {
  let { results } = useResults(hackathon.hackathonId);
  const apiClient = useApiClient();
  const [summary, setSummary] = useState<HackathonSummaryDto | null>(null);

  useEffect(() => {
    apiClient.stats.getHackathonSummary(hackathon.hackathonId).then(setSummary);
  }, [apiClient.stats, hackathon]);

  if (hackathon.currentStage !== HackathonStage.Finished) return null;
  return (
    <>
      <Container>
        <Winners
          hackathonId={hackathon.hackathonId}
          individualWinner={
            (results && results.individualProjects!.length && results.individualProjects![0]) || undefined
          }
          teamWinner={(results && results.teamProjects![0]) || undefined}
        />
        <HackathonSummaryStats summary={summary} />
        <Buttons>
          <ResultsLink to={`/hackathon/${hackathon.hackathonId}/result`}>
            Go to Full Results
            <ResultsIcon icon={faTrophy} color={theme.colors.orange} />
          </ResultsLink>
        </Buttons>
      </Container>
      <AllProjectsTitle>All Projects</AllProjectsTitle>
    </>
  );
};

const Container = styled.div`
  ${theme.styles.materialPanel};
  background: #fff;
  margin: 0 10px;
`;

const AllProjectsTitle = styled.h2`
  margin-top: 2em;
  text-align: center;
  font-weight: 100;
`;

const Buttons = styled.div`
  margin: 0 0 5px 0;
  padding: 15px 0 0 0;
  display: flex;
  align-self: stretch;
`;

const ResultsLink = styled(Link)`
  flex: 1;
  text-align: right;
  text-decoration: none;
  color: #202020;
  transition: color 0.2s;
  &:hover {
    color: orange;
  }
`;

const ResultsIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;
