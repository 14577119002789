import React, { FC } from 'react';
import { useAuth0 } from '../../../Auth0Provider';
import styled from '@emotion/styled';
import theme from '../theme';

export const Profile: FC = () => {
  const { loading, user } = useAuth0();

  if (loading || !user) return null;

  return <ProfileImage src={user.picture} alt={user.name} title={user.name} />;
};

const ProfileImage = styled.img`
  ${theme.styles.materialPanel}
  padding: 0;
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;
