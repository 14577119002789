import styled from '@emotion/styled';
import { faCheck, faInfoCircle, faClock, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { animated, useSpring } from 'react-spring';
import { ResultsDto } from '../../Api';
import theme from '../shared/theme';
import { Link } from 'react-router-dom';

type Props = { results: ResultsDto };
export const VoteStatus: FC<Props> = ({ results }) => {
  if (results.isVotingOpen) {
    return <VotingOpen totalVotes={results.totalVotes} />;
  } else if (!results.hasVotingStarted) {
    return <VotingNotStarted />;
  } else {
    return <VotingClosed />;
  }
};

export const VotingOpen: FC<{ totalVotes: number }> = ({ totalVotes }) => {
  const fadeIn = useSpring(theme.springs.fadeIn);
  return (
    <Container style={fadeIn}>
      <Icon icon={faInfoCircle} size="1x" color={theme.colors.blue} />
      <Message>
        <VoteCount>{totalVotes}</VoteCount> votes so far, and they're still coming in!
      </Message>
    </Container>
  );
};

export const VotingClosed: FC = () => {
  const fadeIn = useSpring(theme.springs.fadeIn);
  return (
    <Container style={fadeIn}>
      <Icon icon={faCheck} size="1x" color={theme.colors.green} />
      <Message>Voting is now closed and results are final!</Message>
    </Container>
  );
};

export const VotingNotStarted: FC = () => {
  const fadeIn = useSpring(theme.springs.fadeIn);
  return (
    <Container style={fadeIn}>
      <Icon icon={faClock} size="1x" color={theme.colors.blue} />
      <Message>
        Voting doesn't open until the demos start. <FontAwesomeIcon icon={faStar} color={theme.colors.orange} /> your
        favourites on the <Link to="/">home page</Link>{' '}
      </Message>
    </Container>
  );
};

const Container = styled(animated.div)`
  ${theme.styles.materialPanel}
  background: #fff;
  margin: 0 20px 20px 20px;
  display: flex;
  align-items: center;
`;

const VoteCount = styled.span`
  font-weight: bold;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 40px;
`;

const Message = styled.div`
  flex: 1;
  font-size: 12px;
  margin-left: 10px;
`;
