import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { TabProps } from './Tab';
import { TabList } from './TabList';
import styled from '@emotion/styled';

type Props = {
  children: ReactNode;
};

export const Tabs: FC<Props> = ({ children, ...forwardedProps }) => {
  const tabs = useMemo(
    () =>
      React.Children.map(children, (child: any) => {
        if (child.props && child.props.title) {
          return child.props as TabProps;
        }
      }).filter(Boolean) as TabProps[],
    [children]
  );

  const [selectedTab, setSelectedTab] = useState(tabs.map(t => t.tabKey)[0]);

  const renderTab = useCallback(
    (child: any) => {
      if (!child || !child.props || !child.props.tabKey) return null;

      if (child.props.tabKey !== selectedTab) return null;

      return child;
    },
    [selectedTab]
  );

  return (
    <Container {...forwardedProps}>
      <TabList select={setSelectedTab} selectedTab={selectedTab} tabs={tabs} />
      {React.Children.map(children, renderTab)}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
