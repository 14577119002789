import { v4 as uuid } from 'uuid';

const ID_KEY = 'device-id';
let deviceId = localStorage.getItem(ID_KEY);
if (!deviceId) {
  deviceId = uuid();
  localStorage.setItem(ID_KEY, deviceId);
}

export const useDeviceId = () => ({ deviceId });
