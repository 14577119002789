import React, { FC, ReactElement } from 'react';
import Loading from '../Loading';

type Props = { required: any; children: ReactElement | (() => ReactElement) };

const getChildren = (children: ReactElement | (() => ReactElement)) =>
  typeof children === 'function' ? children() : children;

export const Require: FC<Props> = ({ required, children }) =>
  required ? getChildren(children) : <Loading />;
