import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Button } from '../components/shared/Button';

type Props = {
  scale: number;
  onClick(): void;
};

export const ClapButton: FC<Props> = (buttonProps) => (
  <StyledButton category="primary" {...buttonProps}>
    <span role="img" aria-label="clap">
      👏
    </span>
  </StyledButton>
);

const StyledButton = styled(Button)<{ scale: number }>`
  padding: 5px 15px;
  font-size: 18px;
  transition: scale 0.1s;
  transform: scale(${(p) => p.scale});
  transform-origin: 100% 100%;
`;
