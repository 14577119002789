import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useDemoSlot } from '../../hooks/useDemoSlot';
import { ProjectHeroImage } from '../HackathonHome/ProjectHeroImage';
import { ShortlistButton } from '../HackathonHome/ShortlistButton';
import theme from '../shared/theme';
import { ConnectionStatus } from './ConnectionStatus';
import { DemoFinished } from './DemoFinished';
import { NextProjectNotification } from './NextProjectNotification';
import { ReactionsEditor } from './ReactionsEditor';

export const LiveDemoStatus: FC = () => {
  const { demoSlot, state } = useDemoSlot();
  if (demoSlot.isComplete) return <DemoFinished />;
  if (!demoSlot.isLive) return null;

  if (!demoSlot.project) return <NextProjectNotification demoSlot={demoSlot} />;

  return (
    <Container key={demoSlot.project.projectId}>
      <ConnectionStatus state={state} />
      <ProjectContainer>
        <HeroImageContainer>
          <ProjectHeroImage project={demoSlot.project} size="md" />
        </HeroImageContainer>
        <Title>{demoSlot.project.name}</Title>
        <ShortlistButton projectId={demoSlot.project.projectId} />
      </ProjectContainer>
      <NextProjectNotification demoSlot={demoSlot} />
      <ReactionsEditor />
    </Container>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  animation: ${fadeIn} 1s forwards;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ProjectContainer = styled.div`
  ${theme.styles.materialPanel};
  padding: 0;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-height: 55px;
  padding-left: 80px;
  margin-left: 10px;
  margin-bottom: 15px;
`;

const HeroImageContainer = styled.div`
  ${theme.styles.materialShadow};
  position: absolute;
  top: -10px;
  left: -10px;
  border-radius: 50%;
`;

const Title = styled.h1`
  text-align: left;
  font-weight: 200;
  font-size: 20px;
  flex: 1;
`;
