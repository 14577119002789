import './wdyr.ts';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { environment } from './environment';
import ReactModal from 'react-modal';

const root = document.getElementById('root');
ReactDOM.render(<App />, root);
ReactModal.setAppElement(root!);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (environment.isDevelopment && module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(<NextApp />, root);
  });
}
