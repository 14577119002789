import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { useAuth0 } from '../../Auth0Provider';
import AppToolbar from '../shared/AppToolbar';
import { Page, PageBody } from '../shared/Layout';
import Require from '../shared/Require';
import { Instructions } from './Instructions';
import { ProjectEditor } from './ProjectEditor';
import { ProjectNavigation } from './ProjectNavigation';
import { useProject } from './useProject';
import Team from '../shared/Team';

type RouteParams = { hackathonId: string; projectId: string };

type Props = { hackathonId: number; projectId: number };

const ProjectHomeComponent: FC<Props> = ({ hackathonId, projectId }) => {
  const { project, previousProjectId, nextProjectId, ...editorProps } = useProject(hackathonId, projectId);
  const { user } = useAuth0();
  const isEditable = !!user && !!project && user.sub === project.ownerId;

  return (
    <>
      <Helmet>
        <title>{(project && project.name) || 'New Project'}</title>
      </Helmet>
      <Page>
        <AppToolbar hackathonId={hackathonId} />
        <PageBody>
          <Require required={project}>
            {() => (
              <ProjectNavigation
                projectId={project!.projectId}
                hackathonId={hackathonId}
                nextProjectId={nextProjectId}
                previousProjectId={previousProjectId}
              >
                <ProjectEditor project={project!} isEditable={isEditable} {...editorProps} />
                {isEditable && <Instructions project={project!} />}
                <Team hackathonId={hackathonId} projectId={projectId} />
              </ProjectNavigation>
            )}
          </Require>
        </PageBody>
      </Page>
    </>
  );
};

export const ProjectHome: FC<RouteComponentProps<RouteParams>> = ({ match }) => (
  <ProjectHomeComponent hackathonId={Number(match.params.hackathonId)} projectId={Number(match.params.projectId)} />
);
