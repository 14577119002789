import { useAuth0 } from '../Auth0Provider';
import { ApiClient } from '../ApiClient';
import { useMemo } from 'react';

export const useApiClient = () => {
  const auth0 = useAuth0();
  // eslint-disable-next-line
  const apiClient = useMemo(() => new ApiClient(auth0), [auth0.isAuthenticated]);
  return apiClient;
};
