import css from '@emotion/css';
import styled from '@emotion/styled';
import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import theme from './theme';

type Props = {
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  multiline?: boolean;
  onChange(value: string): void;
};

type HTMLTextEditor = HTMLInputElement & HTMLTextAreaElement;

export const InlineInput: FC<Props> = props => {
  const { required, defaultValue, value, onChange, multiline, ...forwardedProps } = props;

  const initialValue = defaultValue || value;
  const [isValid, setIsValid] = useState(!required || !!initialValue);
  const input = useRef<HTMLTextEditor>(null);

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLTextEditor>) => {
      const value = e.currentTarget.value;
      const isValueSet = value && !!value.trim().length;
      if (required && !isValueSet) {
        setIsValid(false);
        return;
      }

      setIsValid(true);
      onChange(value);
    },
    [required, onChange]
  );

  const onFocus = useCallback(() => {
    if (!input.current) return;
    input.current.selectionStart = 0;
    input.current.selectionEnd = input.current.value.length;
  }, []);

  const title = isValid ? '' : 'Value is required';

  const editorProps = {
    title,
    defaultValue,
    value,
    isValid,
    onChange: onChangeInput,
    onFocus,
    ref: input,
    ...forwardedProps
  };

  if (multiline) return <TextArea {...editorProps} />;
  else return <Input {...editorProps} />;
};

type TextEditorProps = { isValid: boolean };

const editorStyle = css`
  font-family: inherit;
  border: none;
  outline: none;
  transition-duration: 0.2s;
  transition-property: color, border-color;

  &:disabled {
    background: transparent;
  }
`;

export const Input = styled.input<TextEditorProps>`
  ${editorStyle}
  border-bottom: 1px solid ${theme.colors.borderGrey};
  &:focus {
    border-color: ${p => (p.isValid ? theme.colors.blue : theme.colors.red)};
  }
`;

export const TextArea = styled.textarea<TextEditorProps>`
  ${editorStyle}
  border-left: 2px solid transparent;
  resize: none;
  padding-left: 5px;

  &:focus {
    border-color: ${p => (p.isValid ? theme.colors.blue : theme.colors.red)};
  }
`;
