import React, { FC } from 'react';
import styled from '@emotion/styled';
import theme from '../shared/theme';
import { HubConnectionState } from '@microsoft/signalr';

type Props = {
  state: HubConnectionState;
};

export const ConnectionStatus: FC<Props> = ({ state }) => (
  <Container>
    <Message>{state}</Message>
    <StatusSpot state={state} />
  </Container>
);

const Container = styled.div`
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const colors = {
  [HubConnectionState.Connected]: theme.colors.green,
  [HubConnectionState.Disconnected]: theme.colors.red,
  [HubConnectionState.Connecting]: theme.colors.orange,
  [HubConnectionState.Disconnecting]: theme.colors.orange,
  [HubConnectionState.Reconnecting]: theme.colors.orange
};

const StatusSpot = styled.div<Props>`
  ${theme.styles.materialShadow};
  padding: 0;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  transition: background-color 0.5s;

  background: ${p => colors[p.state]};
`;

const Message = styled.span`
  color: #bbb;
  font-size: 10px;
  margin-right: 0.5em;
  display: inline-block;
`;
