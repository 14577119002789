import { useCallback, useEffect, useReducer, useRef } from 'react';
import { Project, ProjectData } from '../../Api';
import { useApiClient } from '../../hooks/useApiClient';

type State = {
  project: Project | null;
  nextProjectId?: number;
  previousProjectId?: number;
  isUpdating: boolean;
  hasUpdated: boolean;
};

interface LoadCompleted {
  type: 'LOAD_COMPLETED';
  project: Project;
  nextProjectId?: number;
  previousProjectId?: number;
}

interface UpdateStarted {
  type: 'UPDATE_STARTED';
}

interface UpdateCompleted {
  type: 'UPDATE_COMPLETED';
  project: Project;
}

type Actions = LoadCompleted | UpdateStarted | UpdateCompleted;

const initialState: State = {
  project: null,
  isUpdating: false,
  hasUpdated: false
};

const reducer = (state = initialState, { type, ...stateProps }: Actions) => {
  switch (type) {
    case 'UPDATE_STARTED':
      return { ...state, isUpdating: true };
    case 'UPDATE_COMPLETED':
      return { ...state, isUpdating: false, hasUpdated: true, ...stateProps };
    case 'LOAD_COMPLETED':
      return { ...state, ...stateProps };
    default:
      return state;
  }
};

export const useProject = (hackathonId: number, projectId: number) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { project } = state;
  const apiClient = useApiClient();
  const updateTimeout = useRef<number | undefined>();

  useEffect(() => {
    apiClient.project.get(hackathonId, projectId).then(dto =>
      dispatch({
        type: 'LOAD_COMPLETED',
        project: dto.project,
        nextProjectId: dto.nextProjectId,
        previousProjectId: dto.previousProjectId
      })
    );
  }, [hackathonId, projectId, apiClient.project]);

  const pendingUpdates = useRef<Array<Partial<ProjectData>>>([]);
  const sendPendingUpdates = useCallback(async () => {
    if (!project || !pendingUpdates.current.length) return;

    let combinedData: ProjectData = {
      name: project.name,
      category: project.category,
      description: project.description,
      heroImageUrl: project.heroImageUrl,
      teamName: project.teamName,
      shortDescription: project.shortDescription
    };
    pendingUpdates.current.forEach(update => {
      combinedData = { ...combinedData, ...update };
    });

    const updatedProject = await apiClient.project.update(hackathonId, projectId, combinedData);
    dispatch({
      type: 'UPDATE_COMPLETED',
      project: updatedProject!
    });
    pendingUpdates.current = [];
  }, [apiClient.project, project, projectId, hackathonId, pendingUpdates]);

  const update = useCallback(
    async (updatedData: Partial<ProjectData>) => {
      dispatch({ type: 'UPDATE_STARTED' });
      pendingUpdates.current.push(updatedData);
      if (updateTimeout.current) window.clearTimeout(updateTimeout.current);

      updateTimeout.current = window.setTimeout(sendPendingUpdates, 500);
    },
    [pendingUpdates, sendPendingUpdates]
  );

  return { ...state, update };
};
