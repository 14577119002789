import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Reactions } from '../../Api';
import { useDemoSlot } from '../../hooks/useDemoSlot';
import { ReactionButton } from './ReactionButton';

export const ReactionsEditor: FC = () => {
  const { canReact, demoSlot, toggleReaction, currentUserReactions } = useDemoSlot();
  const { reactions } = demoSlot;

  const userReactions: Partial<Record<Reactions, boolean>> = currentUserReactions || {};

  if (!reactions) return null;

  const firstRow = Object.keys(Reactions).map(r => r as Reactions);
  const secondRow = firstRow.splice(firstRow.length / 2);

  const renderReactionButton = (reaction: Reactions) => (
    <ReactionButton
      key={reaction}
      toggleReaction={() => toggleReaction(reaction)}
      isEnabled={!!canReact}
      reaction={reaction}
      count={reactions[reaction] || 0}
      currentUserReacted={userReactions[reaction]}
    />
  );

  return (
    <Container>
      <Row>{firstRow.map(renderReactionButton)}</Row>
      <Row>{secondRow.map(renderReactionButton)}</Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
