import styled from '@emotion/styled';
import { faStopwatch, faLaptop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { DemoStateDto, NextProjectDto } from '../../Api';
import { useAuth0 } from '../../Auth0Provider';
import theme from '../shared/theme';

type Props = {
  demoSlot: DemoStateDto;
};

const noNextDemo: NextProjectDto = { team: [], projectId: -1, name: '' };

export const NextProjectNotification: FC<Props> = ({ demoSlot }) => {
  const { user } = useAuth0();

  const nextDemo = demoSlot.nextDemo || noNextDemo;
  const upNext = user && nextDemo.team.indexOf(user.sub) > -1;

  const nextPlusOneDemo = demoSlot.nextPlusOneDemo || noNextDemo;
  const upNextPlusOne = user && nextPlusOneDemo.team.indexOf(user.sub) > -1;

  if (upNext)
    return (
      <Notification>
        <Icon icon={faStopwatch} />
        <Title>Ready... Set...</Title>
        <Description>
          You're demoing <strong>{nextDemo.name}</strong> next
        </Description>
      </Notification>
    );

  if (upNextPlusOne)
    return (
      <Notification>
        <Icon icon={faLaptop} />
        <Title>Heads up!</Title>
        <Description>
          You're demoing <strong>{nextPlusOneDemo.name}</strong> after {nextDemo.name} (up next) so go grab a laptop
        </Description>
      </Notification>
    );

  return null;
};

const Notification = styled.div`
  ${theme.styles.materialPanel};
  margin: 10px 0;
  padding: 20px;
  background: ${theme.colors.red};
  color: #fff;
  display: grid;
  grid-template:
    'icon title'
    'icon description'
    / auto 1fr;
`;

const Icon = styled(FontAwesomeIcon)`
  grid-area: icon;
  font-size: 40px;
  margin: 0 30px 0 10px;
  align-self: center;
  justify-self: center;
`;

const Title = styled.h4`
  grid-area: title;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
`;

const Description = styled.p`
  grid-area: description;
  margin: 0;
  font-size: 14px;
`;
