import styled from '@emotion/styled';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { AuthenticatedButton } from '../shared/AuthenticatedButton';
import theme from '../shared/theme';
import { ShortlistContext } from './ShortlistProvider';

type Props = { projectId: number };

export const ShortlistButton: FC<Props> = ({ projectId }) => (
  <ShortlistContext.Consumer>
    {({ shortlist, toggle }) => (
      <AuthenticatedButton onClick={() => toggle(projectId)}>
        <StarContainer shortlisted={shortlist[projectId]}>
          <FontAwesomeIcon icon={faStar} />
        </StarContainer>
      </AuthenticatedButton>
    )}
  </ShortlistContext.Consumer>
);

const StarContainer = styled.div<{ shortlisted: boolean | undefined }>`
  border: none;
  background: none;
  outline: none;
  text-align: left;
  font-size: 40px;
  cursor: pointer;
  transition: color 0.5s;
  color: ${({ shortlisted }) => (shortlisted ? theme.colors.orange : theme.colors.borderGrey)};
`;
