import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ProjectCategory } from '../../Api';
import { ProjectHeroImage } from '../HackathonHome/ProjectHeroImage';
import { MinimalProject } from '../Results';

type Props = { hackathonId: number; project?: MinimalProject; category: ProjectCategory };

const titleText = {
  [ProjectCategory.Team]: 'Team Prize',
  [ProjectCategory.Individual]: 'Individual Prize',
};

export const WinningProject: FC<Props> = ({ hackathonId, project, category }) => (
  <Container>
    <CategoryTitle>
      <TitleText>{titleText[category]}</TitleText>
    </CategoryTitle>
    {project && <ProjectHeroImage project={{ hackathonId, ...project }} />}
    <Title>{(project && project.projectName) || '-'}</Title>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 170px;
`;

const CategoryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const TitleText = styled.h5`
  flex: 1;
  margin: 10px;
  font-weight: normal;
  color: #909090;
`;

const Title = styled.h4`
  margin: 10px;
  font-weight: normal;
  text-align: center;
`;
