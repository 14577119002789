import styled from '@emotion/styled';
import { faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { ProjectCategory } from '../../../Api';
import theme from '../theme';

type Props = { category: ProjectCategory; size?: 'sm' | 'md' };

export const ProjectIcon: FC<Props> = ({ category, size }) => (
  <IconContainer category={category} size={size}>
    {category === ProjectCategory.Individual ? (
      <FontAwesomeIcon icon={faUser} />
    ) : (
      <FontAwesomeIcon icon={faUserFriends} />
    )}
  </IconContainer>
);

const IconContainer = styled.div<Props>`
  ${theme.styles.materialPanel}
  color: #fff;

  text-align: center;
  display: inline-block;
  background: ${props => (props.category === ProjectCategory.Individual ? theme.colors.green : theme.colors.red)};
  ${props => sizes[props.size || 'md']}
`;

const sizes: any = {
  sm: {
    padding: '5px 10px',
    height: '10px',
    width: '10px',
    fontSize: '8px'
  },
  md: {
    padding: '15px 20px',
    height: '20px',
    width: '20px',
    fontSize: '16px'
  }
};
