import {
  HackathonClient,
  ProjectClient,
  VoteClient,
  SearchClient,
  ReactionClient,
  StatsClient,
  ChatClient
} from './Api';
import { Auth0State } from './Auth0Provider';
import { environment } from './environment';

export class ApiClient {
  // eslint-disable-next-line
  constructor(private auth0: Auth0State) {}

  async fetch(url: RequestInfo, init: RequestInit = {}): Promise<Response> {
    if (!this.auth0.isAuthenticated) return await fetch(url, init);

    const token = await this.auth0.getTokenSilently();
    const initWithAuth: RequestInit = {
      ...init,
      headers: {
        ...(init.headers && init.headers),
        Authorization: `Bearer ${token}`
      }
    };

    return await fetch(url, initWithAuth);
  }

  hackathon = new HackathonClient(environment.apiUrl, this);
  project = new ProjectClient(environment.apiUrl, this);
  vote = new VoteClient(environment.apiUrl, this);
  search = new SearchClient(environment.apiUrl, this);
  reaction = new ReactionClient(environment.apiUrl, this);
  stats = new StatsClient(environment.apiUrl, this);
  chat = new ChatClient(environment.apiUrl, this);
}
