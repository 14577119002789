import styled from '@emotion/styled';
import { faCheckCircle, faVoteYea, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { ButtonLink } from '../shared/Button';
import { HackathonContext } from '../shared/HackathonContext';
import theme from '../shared/theme';
import { keyframes } from '@emotion/core';

export const DemoFinished: FC = () => {
  const { hackathonId } = useContext(HackathonContext);
  return (
    <Container>
      <FontAwesomeIcon icon={faCheckCircle} size="4x" color={theme.colors.green} />
      <Message>Demos are Finished!</Message>
      <Buttons>
        <ButtonLink to={`/hackathon/${hackathonId}/result`} category="invisible">
          <Icon icon={faTrophy} />
          View Results
        </ButtonLink>
        <ButtonLink to={`/hackathon/${hackathonId}/vote`} category="primary">
          <Icon icon={faVoteYea} />
          Cast your Vote
        </ButtonLink>
      </Buttons>
    </Container>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  ${theme.styles.materialPanel};
  animation: ${fadeIn} 1s forwards;
  margin: 10px auto;
  max-height: 200px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Message = styled.h2`
  font-weight: 200;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    flex: 1;
    margin: 10px;
    white-space: nowrap;
  }
`;
