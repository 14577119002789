/* eslint-disable jsx-a11y/accessible-emoji */
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, FocusEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import { ImageResultDto } from '../../../Api';
import { useApiClient } from '../../../hooks/useApiClient';
import { HeroImage } from '../../HackathonHome/ProjectHeroImage';
import { AsyncStatusIcon } from '../../ProjectHome/AsyncStatusIcon';
import { InlineInput } from '../InlineInput';
import theme from '../theme';

type Props = {
  imageUrl: string;
  onChange(imageUrl: string): void;
};

const defaultSearches = ['animal icon', 'flame', 'rocket icon', 'pokemon', 'hacker', 'space', 'bubbles'];
const randomSearch = () => defaultSearches[Math.floor(Math.random() * defaultSearches.length)];

export const ImageSearch: FC<Props> = ({ imageUrl, onChange }) => {
  const apiClient = useApiClient();
  const [searchTerm, setSearchTerm] = useState(randomSearch());
  const [imageResults, setImageResults] = useState<ImageResultDto[] | null>(null);
  const [searchKey, setSearchKey] = useState(0);
  const throttleTimer = useRef<number>();

  useEffect(() => {
    if (throttleTimer.current) window.clearTimeout(throttleTimer.current);
    if (!searchTerm || !searchTerm.trim().length) return;

    async function search() {
      const current = throttleTimer.current;
      setImageResults(null);
      const results = await apiClient.search.findImages(searchTerm);

      if (current === throttleTimer.current) {
        setSearchKey((k) => k + 1);
        setImageResults(results);
      }
    }

    throttleTimer.current = window.setTimeout(search, 500);
    setImageResults(null);

    return () => {
      window.clearTimeout(throttleTimer.current);
      throttleTimer.current = 0;
    };
  }, [searchTerm, setImageResults, apiClient.search, setSearchKey]);

  const isSearching = !!searchTerm && !imageResults;

  const blur = useCallback((event: FocusEvent<any>) => {
    event.preventDefault();
    const currentInput = document.activeElement as HTMLInputElement;
    if (currentInput) currentInput.blur();
  }, []);

  return (
    <Container>
      <Description>Type below to search for images online</Description>
      <InputContainer onSubmit={blur} action="">
        <SearchIcon icon={faSearch} />
        <SearchInput placeholder="Type to search" defaultValue={searchTerm || ''} onChange={setSearchTerm} />
        <input type="submit" hidden />
        <AsyncStatusIcon isRunning={isSearching} />
      </InputContainer>
      <ImageResults isSearching={isSearching}>
        <ImageResultButtons key={searchKey} imageResults={imageResults}>
          {({ item, key, props }) => (
            <SearchResultButton
              key={key}
              style={props}
              onClick={() => onChange(item.thumbnail)}
              selected={item.thumbnail === imageUrl}
            >
              <HeroImage size="md" url={item.thumbnail} />
            </SearchResultButton>
          )}
        </ImageResultButtons>
        {!imageResults && !isSearching && <Placeholder>Search above to find a 💄 image for your project</Placeholder>}
        {isSearching && <Placeholder>Searching...</Placeholder>}
        {imageResults && !imageResults.length && <Placeholder>No results</Placeholder>}
      </ImageResults>
    </Container>
  );
};

// Wrapping useTransition so we can explicitly specify a key and force a re-render
const ImageResultButtons: FC<{
  imageResults: ImageResultDto[] | null;
  children(props: { item: ImageResultDto; key: string; props: any }): ReactNode;
}> = (props) => {
  const { imageResults, children } = props;
  const transitions = useTransition(imageResults || [], (r) => r.thumbnail, slideImagesIn);
  return <>{transitions.map(children)}</>;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  margin: 5px;
  color: ${theme.colors.blue};
  margin-right: 10px;
`;

const Placeholder = styled.p`
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${theme.colors.lightGrey};
`;

const SearchInput = styled(InlineInput)`
  margin-right: 10px;
  font-size: 16px;
  flex: 1;
`;

const InputContainer = styled.form`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const SearchResultButton = styled(animated.button)<{ selected: boolean }>`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }

  & > * {
    transition: transform 0.1s;
  }

  ${(p) =>
    p.selected &&
    css`
      position: relative;
      z-index: 1;
      & > * {
        transform: scale(1.2);
        box-shadow: 0px 0px 5px ${theme.colors.blue};
      }
    `}
`;

const ImageResults = styled.div<{ isSearching: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: auto;
  /* padding+margin to accomodate scaled children */
  padding: 15px;
  margin: -15px;
  transition: opacity 0.5s;
  opacity: ${(p) => (p.isSearching ? 0.2 : 1)};
`;

const Description = styled.p`
  font-size: 11px;
`;

const slideImagesIn = {
  from: { transform: 'translate3d(100%,0,0)', opacity: 0 },
  enter: { transform: 'translate3d(0,0,0)', opacity: 1 },
  exit: { transform: 'translate3d(-100%,0,0)', opacity: 0 },
  trail: 25,
};
